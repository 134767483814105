import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { NavLink } from "react-router-dom";
import Banner01 from "../assets/img/banner01.jpg";
import Banner02 from "../assets/img/banner02.jpg";
import Banner03 from "../assets/img/banner03.jpg";
import Banner04 from "../assets/img/banner04.jpg";

function HomeBanner() {
  const homeSliderItems = [
    {
      id: 1,
      imageName: Banner01,
      title: "Exploring the wonders of Phytochemicals ",
      description:
        "Embark on a fascinating journey into the heart of phytochemicals with our captivating exploration.",
    },
    {
      id: 2,
      imageName: Banner02,
      title: "Tiny Wonders: Microbes Explored",
      description:
        "Dive into the unseen world of microbes, shaping ecosystems, human health, and biotechnological frontiers.",
    },
    {
      id: 3,
      imageName: Banner03,
      title: "Bioengineering Frontiers",
      description:
        " Journey into bioengineering's frontier, merging science and innovation to reshape healthcare, create artificial organs, and enhance human capabilities.",
    },
    {
      id: 4,
      imageName: Banner04,
      title: "Eco Harmony: Biodiversity Explored",
      description:
        " Witness the delicate balance of ecosystems and biodiversity, shaping conservation efforts through bioscience research, safeguarding our planet's ecological harmony.",
    },
  ];
  return (
    <div>
      <div className="HomeSlider">
        <div className="container-fluid g-0">
          <div className="row g-0 justify-content-between">
            <div className="col-md-7 align-self-center">
              <article className="article-slider">
                <div className="row justify-content-end">
                  <div className="col-md-9 col-sm-10">
                    <article className="p-2 p-md-4">
                      <h2 className="font-bold">
                        Your Strategic Partner for <span>Phytochemicals </span>{" "}
                        Reference Standards
                      </h2>
                      <p className="d-none d-md-block">
                        Embark on a fascinating journey into the heart of
                        phytochemicals with our captivating exploration.
                      </p>
                      <div>
                        <NavLink to="/About" className="green-btn">
                          Read More
                        </NavLink>
                      </div>
                    </article>
                    <section className="highlets my-2 my-md-5 d-none d-md-block">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="highlets-col rounded d-flex p-3 border">
                            <div className="icon">
                              <span class="icon-award icomoon font-secondary"></span>
                            </div>
                            <div className="ps-3 align-self-center">
                              <h5 className="h5 font-black">
                                100% Customer Satisfaction
                              </h5>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="highlets-col rounded d-flex p-3 border">
                            <div className="icon">
                              <span class="icon-trophy icomoon font-secondary"></span>
                            </div>
                            <div className="ps-3 align-self-center">
                              <h5 className="h5 font-black">Best Lab Awards</h5>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="highlets-col rounded d-flex p-3 border">
                            <div className="icon">
                              <span class="icon-observation icomoon font-secondary"></span>
                            </div>
                            <div className="ps-3 align-self-center">
                              <h5 className="h5 font-black">
                                20 Years Experience
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </article>
              {/*Home Hightlets*/}
            </div>
            <div className="col-md-4">
              <Swiper
                pagination={{ clickable: true }}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                className="mySwiper"
              >
                {homeSliderItems.map((item) => (
                  <SwiperSlide key={item.id}>
                    <div className="bannerimage">
                      <img src={item.imageName} alt="" className="bannerimg" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeBanner;
