//Phytochemicals Data

const phytochemicals = [
  {
    id: 1,
    Image: "/products/TurmeroneAr-532-65-0.jpg",
    Product: "(S)-(+)-Turmerone,Ar",
    BotanicalSource: "Curcuma longa",
    IUPACName: "2-methyl-6S-(4-methylphenyl)-2-hepten-4-one",
    Synonyms: "Ar-Turmerone",
    CASNumber: "532-65-0",
    MolecularFormula: "C15H20O",
    MolecularWeight: 216.32,
    Appearance: "Light yellow color viscous liquid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC1=CC=C([C@@H](C)CC(/C=C(C)/C)=O)C=C1",
    InChICode:
      "InChI=1S/C15H20O/c1-11(2)9-15(16)10-13(4)14-7-5-12(3)6-8-14/h5-9,13H,10H2,1-4H3/t13-/m0/s1",
    InChIKey: "NAAJVHHFAXWBOK-ZDUSSCGKSA-N",
    PubChemCID: 160512,
    MDLNumber: "MFCD09031381",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 2,
    Image: null,
    Product: "1,9-Dideoxyforskolin",
    BotanicalSource: "Coleus forskohlii",
    IUPACName: "7β-Acetoxy-6β-hydroxy-8,13-epoxy-labd-14-en-11-one",
    Synonyms: null,
    CASNumber: "64657-18-7",
    MolecularFormula: "C22H34O5",
    MolecularWeight: 378.5,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[H][C@@]12[C@H](O)[C@H](OC(C)=O)[C@@]3(C)O[C@](C)(CC(=O)C3[C@@]1(C)CCCC2(C)C)C=C",
    InChICode:
      "1S/C22H34O5/c1-8-20(5)12-14(24)16-21(6)11-9-10-19(3,4)17(21)15(25)18(26-13(2)23)22(16,7)27-20/h8,15-18,25H,1,9-12H2,2-7H3/t15-,16?,17-,18-,20-,21+,22-/m0/s1",
    InChIKey: "ZKZMDXUDDJYAIB-OJPJTMFRSA-N",
    PubChemCID: 107948,
    MDLNumber: "MFCD00082318",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 3,
    Image: "/products/11-Keto-boswellic-acid-17019-92-0.jpg",
    Product: "11-Keto β boswellic acid",
    BotanicalSource: "Boswellia serrata",
    IUPACName:
      "(3α,4β)-3-Hydroxy-11-oxours-12-en-23-oic Acid; 3α-Hydroxy-11-oxours-12-en-24-oic Acid",
    Synonyms: "11-Oxo-β-boswellic Acid",
    CASNumber: "17019-92-0",
    MolecularFormula: "C30H46O4",
    MolecularWeight: 470.68,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1C=C2[C@](CC[C@]3(C)C2[C@@H](C)[C@H](C)CC3)(C)[C@]4(C)CCC5[C@@](C)(C(O)=O)[C@H](O)CC[C@]5(C)C41",
    InChICode:
      " 1S/C30H46O4/c1-17-8-11-26(3)14-15-28(5)19(23(26)18(17)2)16-20(31)24-27(4)12-10-22(32)30(7,25(33)34)21(27)9-13-29(24,28)6/h16-18,21-24,32H,8-15H2,1-7H3, (H,33,34)/t17-,18+,21?,22-,23?,24?,26-,27+,28-,29-,30?/m1/s1",
    InChIKey: "YIMHGPSYDOGBPI-BVIVALHHSA-N",
    PubChemCID: 9847548,
    MDLNumber: "MFCD06656312",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 4,
    Image: "/products/12-Deoxywithastramonolide-60124-17-6.jpg",
    Product: "12-Deoxywithastramonolide",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      " (5α,6α,7α,22R)-6,7-Epoxy-5,22,27-trihydroxy-1-oxoergosta-2,24-dien-26-oic acid δ-lactone",
    Synonyms: "27-Hydroxywithanolide B",
    CASNumber: "60124-17-6",
    MolecularFormula: "C28H38O6",
    MolecularWeight: 470.6,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(C1)=C(CO)C(O[C@@]1([H])[C@@H](C)[C@@]2([H])CC[C@@]3([H])[C@]4([H])[C@H]5[C@H](O5)[C@@]([C@@]6(C)[C@@]4([H])CC[C@@]32C)(O)CC=CC6=O)=O",
    InChICode:
      "1S/C28H38O6/c1-14-12-20(33-25(31)16(14)13-29)15(2)17-7-8-18-22-19(9-11-26(17,18)3)27(4)21(30)6-5-10-28(27,32)24-23(22)34-24/h5-6,15,17-20,22-24,29,32H,7-13H2,1-4H3/t15-,17+,18-,19-,20+,22-,23-,24-,26+,27-,28-/m0/s1",
    InChIKey: "AWVMHXZWAKRDGG-MEBIVHGNSA-N",
    PubChemCID: 44576309,
    MDLNumber: " MFCD03788778",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 5,
    Image: "/products/14-Deoxy-11-12-didehydroandrographolide-42895-58-9.jpg",
    Product: "14-Deoxy-11,12-didehydroandrographolide",
    BotanicalSource: "Andrographis paniculata",
    IUPACName:
      "3-[(1E)-2-[(1R,4aS,5R,6R,8aR)-decahydro-6-hydroxy-5-(hydroxymethyl)-5,8a-dimethyl-2-methylene-1-naphthalenyl]ethenyl]-2(5H)-furanone",
    Synonyms: "14-dehydro Andrographolide",
    CASNumber: "42895-58-99",
    MolecularFormula: "C20H28O4",
    MolecularWeight: 332.43,
    Appearance: " Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C=C1CC[C@]2([H])[C@@](CO)(C)[C@H](O)CC[C@@]2(C)[C@@H]1/C=C/C3=CCOC3=O",
    InChICode:
      " 1S/C20H28O4/c1-13-4-7-16-19(2,10-8-17(22)20(16,3)12-21)15(13)6-5-14-9-11-24-18(14)23/h5-6,9,15-17,21-22H,1,4,7-8,10-12H2,2-3H3/b6-5+/t15-,16+,17-,19+,20+/m1/s1",
    InChIKey: "XMJAJFVLHDIEHF-CRBRZBHVSA-N ",
    PubChemCID: 5708351,
    MDLNumber: "MFCD07778081",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 6,
    Image: "/products/2-3-Dihydrowithaferin-A-21902-96-5.jpg",
    Product: "2,3-Dihydro-3-methoxywithaferin A",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "(1S,2R,5S,6S,7R,9R,11S,12S,15R,16S)-6-hydroxy-15-[(1S)-1-[(2R)-5-(hydroxymethyl)-4-methyl-6-oxo-2,3-dihydropyran-2-yl]ethyl]-5-methoxy-2,16-dimethyl-8-oxapentacyclo[9.7.0.02,7.07,9.012,16]octadecan-3-one",
    Synonyms: "Quresimine A",
    CASNumber: "21902-96-5",
    MolecularFormula: "C29H42O7",
    MolecularWeight: 502.64,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC1=C(C(=O)OC(C1)C(C)C2CCC3C2(CCC4C3CC5C6(C4(C(=O)CC(C6O)OC)C)O5)C)CO",
    InChICode:
      "1S/C29H42O7/c1-14-10-21(35-26(33)17(14)13-30)15(2)18-6-7-19-16-11-24-29(36-24)25(32)22(34-5)12-23(31)28(29,4)20(16)8-9-27(18,19)3/h15-16,18-22,24-25,30,32H,6-13H2,1-5H3/t15-,16-,18+,19-,20-,21+,22-,24+,25-,27+,28-,29-/m0/s1",
    InChIKey: "MKTMIPAPOLDOQT-QAYSIJLNSA-N",
    PubChemCID: 10767792,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 7,
    Image: null,
    Product: "28-Deoxonimbolide",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "•	methyl 2-[(1R,2S,4R,6R,9R,10S,11R,15R,18S)-6-(furan-3-yl)-7,9,11,15-tetramethyl-12-oxo-3,17-dioxapentacyclo[9.6.1.02,9.04,8.015,18]octadeca-7,13-dien-10-yl]acetate",
    Synonyms: "28-Deoxonimbolide",
    CASNumber: "126005-94-5",
    MolecularFormula: "C27H32O6",
    MolecularWeight: 452.5,
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC1=C2[C@@H](C[C@H]1C3=COC=C3)O[C@H]4[C@@]2([C@@H]([C@@]5([C@H]6[C@H]4OC[C@@]6(C=CC5=O)C)C)CC(=O)OC)C",
    InChICode:
      "1S/C27H32O6/c1-14-16(15-7-9-31-12-15)10-17-21(14)27(4)18(11-20(29)30-5)26(3)19(28)6-8-25(2)13-32-22(23(25)26)24(27)33-17/h6-9,12,16-18,22-24H,10-11,13H2,1-5H3/t16-,17-,18-,22-,23+,24-,25+,26+,27-/m1/s1",
    InChIKey: "CWGBIWRWBCYASK-LMHNVORZSA-N",
    PubChemCID: 14467538,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 8,
    Image: "/products/3-Acetyl-11-keto-boswellic-acid-67416-61-9.jpg",
    Product: "3-Acetyl-11-keto βboswellic acid(AKBA)",
    BotanicalSource: "Boswellia serrata",
    IUPACName: "(3α,4β)-3-(acetyloxy)-11-oxo-urs-12-en-23-oic acid",
    Synonyms: "AKBA",
    CASNumber: "67416-61-9",
    MolecularFormula: "C32H48O5",
    MolecularWeight: 512.72,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1C=C2[C@](CC[C@]3(C)[C@@]2([H])[C@@H](C)[C@H](C)CC3)(C)[C@]4(C)CC[C@@]5([H])[C@@](C)(C(O)=O)[C@H](OC(C)=O)CC[C@]5(C)[C@]41[H]",
    InChICode:
      "InChI=1S/C32H48O5/c1-18-9-12-28(4)15-16-30(6)21(25(28)19(18)2)17-22(34)26-29(5)13-11-24(37-20(3)33)32(8,27(35)36)23(29)10-14-31(26,30)7/h17-19,23-26H,9-16H2,1-8H3,(H,35,36)/t18-,19+,23-,24-,25+,26-,28-,29+,30-,31-,32?/m1/s1",
    InChIKey: "HMMGKOVEOFBCAU-HUXDCABZSA-N",
    PubChemCID: 11168203,
    MDLNumber: "MFCD03788777",
    type: "inStock",
  },
  {
    id: 9,
    Image: null,
    Product: "7-Deacetyl azadiradione",
    BotanicalSource: "Azadirchta indica",
    IUPACName: "",
    Synonyms: null,
    CASNumber: null,
    MolecularFormula: "C26H32O4",
    MolecularWeight: 408.6,
    Appearance: "White Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 10,
    Image: "/products/7-Deacetylazadirone.jpg",
    Product: "7-Deacetylazadirone",
    BotanicalSource: "Azadirchta indica",
    IUPACName: null,
    Synonyms: null,
    CASNumber: null,
    MolecularFormula: "C26H32O3",
    MolecularWeight: 394.6,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 11,
    Image: "/products/7-Deacetylepoxyazadiradione.jpg",
    Product: "7-Deacetyl epoxyazadiradione",
    BotanicalSource: "Azadirchta indica",
    IUPACName: null,
    Synonyms: null,
    CASNumber: null,
    MolecularFormula: "C26H32O5",
    MolecularWeight: 424.6,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 12,
    Image: "/products/7-Deacetylforskolin-64657-20-1.jpg",
    Product: "7-Deacetylforskolin",
    BotanicalSource: "Coleus forskohlii",
    IUPACName:
      "(3R,4aR,5S,6S,6aS,10S,10aR,10bS)-3-ethenyldodecahydro-5,6,10,10b-tetrahydroxy-3,4a,7,7,10a-pentamethyl-1H-naphtho[2,1-b]pyran-1-one",
    Synonyms: "Forskolin D",
    CASNumber: "64657-20-1",
    MolecularFormula: "C20H32O6",
    MolecularWeight: 368.5,
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1C[C@](C)(C=C)O[C@]2(C)[C@@H](O)[C@@H](O)[C@@]3([H])C(C)(C)CC[C@H](O)[C@]3(C)[C@]21O",
    InChICode:
      "1S/C20H32O6/c1-7-17(4)10-12(22)20(25)18(5)11(21)8-9-16(2,3)14(18)13(23)15(24)19(20,6)26-17/h7,11,13-15,21,23-25H,1,8-10H2,2-6H3/t11-,13-,14-,15-,17-,18-,19+,20-/m0/s1",
    InChIKey: "WPDITXOBNLYZHH-KAACEJSMSA-N",
    PubChemCID: 2964,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 13,
    Image: "/products/7-Deacetylgedunin-10314-90-6.jpg",
    Product: "7-Deacetylgedunin",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "(1S,3aS,4aR,4bS,5R,6aR,10aR,10bR,12aS)-1-(furan-3-yl)-5-hydroxy-4b,7,7,10a,12a-pentamethyl-1,5,6,6a,7,10a,10b,11,12,12a-decahydronaphtho[2,1-f]oxireno[2,3-d]isochromene-3,8(3aH,4bH)-dione",
    Synonyms: "7-Deacetoxy-7-hydroxygedunin",
    CASNumber: "10314-90-6",
    MolecularFormula: "C26H32O6",
    MolecularWeight: 440.52,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@@]12CC[C@@H]3[C@]4(C=CC(=O)C([C@@H]4C[C@H]([C@]3([C@@]15[C@H](O5)C(=O)O[C@H]2c6ccoc6)C)O)(C)C)C",
    InChICode:
      " 1S/C26H32O6/c1-22(2)16-12-18(28)25(5)15(23(16,3)9-7-17(22)27)6-10-24(4)19(14-8-11-30-13-14)31-21(29)20-26(24,25)32-20/h7-9,11,13,15-16,18-20,28H,6,10,12H2,1-5H3/t15-,16+,18-,19+,20-,23-,24+,25+,26-/m1/s1",
    InChIKey: "HCEYJYMNIQHPPK-DXTZDJJUSA-N",
    PubChemCID: 3034112,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 14,
    Image: null,
    Product: "Acetyl boswellic acids (α+β)",
    BotanicalSource: "Boswellia serrata",
    IUPACName: null,
    Synonyms: null,
    CASNumber: "89913-60-0(α) 5968-70-7(β)",
    MolecularFormula: "C32H50O4",
    MolecularWeight: 498.7,
    Appearance: "Off White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 15,
    Image: "/products/Alantolactone-546-43-0.jpg",
    Product: "Alantolactone",
    BotanicalSource: "Inula racemosa",
    IUPACName:
      "(3aR,5S,8aR,9aR)-3a,5,6,7,8,8a,9,9a-Octahydro-5,8a-dimethyl-3-methylene-naphtho[2,3-b]furan-2(3H)-one",
    Synonyms: "Alant camphor",
    CASNumber: "546-43-0",
    MolecularFormula: "C15H20O2",
    MolecularWeight: 232.32,
    Appearance: "White crystalline powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C[C@H]1CCC[C@]2(C)C[C@H]3OC(=O)C(=C)[C@H]3C=C12",
    InChICode:
      "InChI=1S/C15H20O2/c1-9-5-4-6-15(3)8-13-11(7-12(9)15)10(2)14(16)17-13/h7,9,11,13H,2,4-6,8H2,1,3H3/t9-,11+,13+,15+/m1/s1",
    InChIKey: "PXOYOCNNSUAQNS-AGNJHWRGSA-N",
    PubChemCID: 72724,
    MDLNumber: "MFCD00274568",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 16,
    Image: "/products/Aloe-emodin-481-72-1.jpg",
    Product: "Aloe emodin",
    BotanicalSource: "Aloe barbadensis",
    IUPACName: "1,8-dihydroxy-3-(hydroxymethyl)-9,10-anthracenedione",
    Synonyms: "3-Hydroxymethylchrysazine",
    CASNumber: "481-72-1",
    MolecularFormula: "C15H10O5",
    MolecularWeight: 270.24,
    Appearance: "white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C2=CC(CO)=CC(O)=C2C(C3=C1C=CC=C3O)=O",
    InChICode:
      "InChI=1S/C15H10O5/c16-6-7-4-9-13(11(18)5-7)15(20)12-8(14(9)19)2-1-3-10(12)17/h1-5,16-18H,6H2",
    InChIKey: "YDQWDHRMZQUTBA-UHFFFAOYSA-N",
    PubChemCID: 10207,
    MDLNumber: "MFCD00017373",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 17,
    Image: "/products/Andrograpanin-82209-74-3.jpg",
    Product: "Andrograpanin",
    BotanicalSource: "Andrographis paniculata",
    IUPACName:
      "3,14-Dideoxyandrographolide, 3-[2-[(1R,4aS,5R,8aS)-Decahydro-5-(hydroxymethyl)-5,8a-dimethyl-2-methylene-1-naphthalenyl]ethyl]-2(5H)-furanone, ent-19-Hydroxy-8(17),13-labdadien-16,15-olide",
    Synonyms: "3,14-Dideoxyandrographolide",
    CASNumber: "82209-74-3",
    MolecularFormula: "C20H30O3",
    MolecularWeight: 318.45,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C[C@@]1(CO)CCC[C@@]2(C)[C@H](CCC3=CCOC3=O)C(=C)CC[C@H]12 ",
    InChICode:
      "1S/C20H30O3/c1-14-5-8-17-19(2,13-21)10-4-11-20(17,3)16(14)7-6-15-9-12-23-18(15)22/h9,16-17,21H,1,4-8,10-13H2,2-3H3/t16-,17-,19+,20+/m1/s1",
    InChIKey: "WKKBRRFSRMDTJB-JYBIWHBTSA-N ",
    PubChemCID: 11666871,
    MDLNumber: "MFCD07778568",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 18,
    Image: "/products/Andrographolide-5508-58-7.jpg",
    Product: "Andrographolide",
    BotanicalSource: "Andrographis paniculata",
    IUPACName:
      "(3E,4S)-3-[2-[(1R,4aS,5R,6R,8aS)-Decahydro-6-hydroxy-5-(hydroxymethyl)-5,8a-dimethyl-2-methylene-1-naphthalenyl]ethylidene]dihydro-4-hydroxy-2(3H)-furanone",
    Synonyms: "Andrographis",
    CASNumber: "5508-58-7",
    MolecularFormula: "C20H30O5",
    MolecularWeight: 350.5,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C=C1CC[C@]2([H])[C@@](CO)(C)[C@H](O)CC[C@@]2(C)[C@@H]1/C=C/C3C(OC[C@H]3O)=O",
    InChICode:
      "1S/C20H30O5/c1-12-4-7-16-19(2,9-8-17(23)20(16,3)11-21)14(12)6-5-13-15(22)10-25-18(13)24/h5,14-17,21-23H,1,4,6-11H2,2-3H3/b13-5+/t14-,15-,16+,17-,19+,20+/m1/s1",
    InChIKey: "BOJKULTULYSRAS-OTESTREVSA-N",
    PubChemCID: 5318517,
    MDLNumber: "MFCD07778082",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 19,
    Image: "/products/Angelicin(Isopsoralen)-523-50-2.jpg",
    Product: "Angelicin (Isopsoralen)",
    BotanicalSource: "Psoralea corylifolia",
    IUPACName: "furo[2,3-h]chromen-2-one",
    Synonyms: "Isopsoralen",
    CASNumber: "523-50-2",
    MolecularFormula: "C11H6O3",
    MolecularWeight: 186.17,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C1=CC2=C(C=CO2)C3=C1C=CC(=O)O3",
    InChICode: "1S/C11H6O3/c12-10-4-2-7-1-3-9-8(5-6-13-9)11(7)14-10/h1-6H",
    InChIKey: "XDROKJSWHURZGO-UHFFFAOYSA-N",
    PubChemCID: 10658,
    MDLNumber: "MFCD00064930",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 20,
    Image: "/products/Azadiradione-26241-51-0.jpg",
    Product: "Azadiradione",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "[(5R,7R,8R,9R,10R,13S,17R)-17-(furan-3-yl)-4,4,8,10,13-pentamethyl-3,16-dioxo-6,7,9,11,12,17-hexahydro-5H-cyclopenta[a]phenanthren-7-yl] acetate",
    Synonyms: null,
    CASNumber: "26241-51-0",
    MolecularFormula: "C28H34O5",
    MolecularWeight: 450.6,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=O)O[C@@H]1C[C@@H]2[C@](C=CC(=O)C2(C)C)([C@@H]3[C@@]1(C4=CC(=O)[C@H]([C@@]4(CC3)C)C5=COC=C5)C)C",
    InChICode:
      "1S/C28H34O5/c1-16(29)33-23-14-20-25(2,3)22(31)8-11-26(20,4)19-7-10-27(5)21(28(19,23)6)13-18(30)24(27)17-9-12-32-15-17/h8-9,11-13,15,19-20,23-24H,7,10,14H2,1-6H3/t19-,20+,23-,24-,26-,27-,28-/m1/s1",
    InChIKey: "KWAMDQVQFVBEAU-HMWIRDDCSA-N",
    PubChemCID: 12308714,
    MDLNumber: "MFCD16036226",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 21,
    Image: "/products/Azadirone-25279-67-8.jpg",
    Product: "Azadirone",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "•	(5alpha,7alpha,13alpha,17alpha)-17-(furan-3-yl)-4,4,8-trimethyl-3-oxoandrosta-1,14-dien-7-yl acetate",
    Synonyms: null,
    CASNumber: "25279-67-8",
    MolecularFormula: "C28H36O4",
    MolecularWeight: 436.6,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC(=O)OC1CC2C(C(=O)C=CC2(C3C1(C4=CCC(C4(CC3)C)C5=COC=C5)C)C)(C)C",
    InChICode:
      "1S/C28H36O4/c1-17(29)32-24-15-22-25(2,3)23(30)10-13-27(22,5)21-9-12-26(4)19(18-11-14-31-16-18)7-8-20(26)28(21,24)6/h8,10-11,13-14,16,19,21-22,24H,7,9,12,15H2,1-6H3/t19-,21+,22-,24+,26-,27+,28-/m0/s1",
    InChIKey: "XXIKKMLIDXLAIK-RFKFVWFBSA-N",
    PubChemCID: 10906239,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 22,
    Image: "/products/Bakuchiol-10309-37-2.jpg",
    Product: "Bakuchiol",
    BotanicalSource: "Psoralea corylifolia",
    IUPACName: "4-[(1E,3S)-3-Ethenyl-3,7-dimethyl-1,6-octadien-1-yl]-phenol",
    Synonyms: "(S)-(+)-Bakuchiol",
    CASNumber: "10309-37-2",
    MolecularFormula: "C₁₈H₂₄O",
    MolecularWeight: 256.38,
    Appearance: "orange red color viscous liquid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=CC=C(/C=C/[C@](C)(C=C)CC/C=C(C)C)C=C1",
    InChICode:
      "InChI=1S/C18H24O/c1-5-18(4,13-6-7-15(2)3)14-12-16-8-10-17(19)11-9-16/h5,7-12,14,19H,1,6,13H2,2-4H3/b14-12+/t18-/m1/s1",
    InChIKey: "LFYJSSARVMHQJB-QIXNEVBVSA-N",
    PubChemCID: 5468522,
    MDLNumber: "MFCD01707441",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 23,
    Image: "/products/Betulin-473-98-3.jpg",
    Product: "Betulin",
    BotanicalSource: "Betula alba",
    IUPACName:
      "(1R,3aS,5aR,5bR,7aR,9S,11aR,11bR,13aR,13bR)-3a-(Hydroxymethyl)-5a,5b,8,8,11a-pentamethyl-1-prop-1-en-2-yl-1,2,3,4,5,6,7,7a,9,10,11,11b,12,13,13a,13b-hexadecahydrocyclopenta[a]chrysen-9-ol",
    Synonyms: "Betulinol",
    CASNumber: "473-98-3",
    MolecularFormula: "C30H50O2",
    MolecularWeight: 442.72,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[H][C@]12CC[C@]3([H])[C@@]4(C)CC[C@H](O)C(C)(C)[C@]4([H])CC[C@@]3(C)[C@]1(C)CC[C@@]5(CO)CC[C@@H](C(C)=C)[C@]25[H]",
    InChICode:
      "1S/C30H50O2/c1-19(2)20-10-15-30(18-31)17-16-28(6)21(25(20)30)8-9-23-27(5)13-12-24(32)26(3,4)22(27)11-14-29(23,28)7/h20-25,31-32H,1,8-18H2,2-7H3/t20-,21+,22-,23+,24-,25+,27-,28+,29+,30+/m0/s1",
    InChIKey: "FVWJYYTZTCVBKE-ROUWMTJPSA-N",
    PubChemCID: "72326",
    MDLNumber: "MFCD00016802",
    InventoryStatus: "Available in milligrams",
    type: "inStock",
  },
  {
    id: 24,
    Image: "/products/Betulinic-acid-472-15-1.jpg",
    Product: "Betulinic acid",
    BotanicalSource: "Betula alba",
    IUPACName:
      "(1R,3aS,5aR,5bR,7aR,9S,11aR,11bR,13aR,13bR)-9-hydroxy-5a,5b,8,8,11a-pentaMethyl-1-(prop-1-en-2-yl)icosahydro-1H-cyclopenta[a]chrysene-3a-carboxylic acid",
    Synonyms: "Lupatic acid",
    CASNumber: "472-15-1",
    MolecularFormula: "C30H48O3",
    MolecularWeight: 456.7,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=C)[C@@H]1CC[C@@]2(CC[C@]3(C)[C@H](CC[C@@H]4[C@@]5(C)CC[C@H](O)C(C)(C)[C@@H]5CC[C@@]34C)[C@@H]12)C(O)=O",
    InChICode:
      "1S/C30H48O3/c1-18(2)19-10-15-30(25(32)33)17-16-28(6)20(24(19)30)8-9-22-27(5)13-12-23(31)26(3,4)21(27)11-14-29(22,28)7/h19-24,31H,1,8-17H2,2-7H3,(H,32,33)/t19-,20+,21-,22+,23-,24+,27-,28+,29+,30-/m0/s1",
    InChIKey: "QGJZLNKBHJESQX-FZFNOLFKSA-N",
    PubChemCID: "64971",
    MDLNumber: "MFCD00009619",
    InventoryStatus: "Available in milligrams",
    type: "inStock",
  },
  {
    id: 25,
    Image: "/products/Bisdemethoxycurcumin-24939-16-0.jpg",
    Product: "Bisdemethoxycurcumin",
    BotanicalSource: "Curcuma longa",
    IUPACName: "1,7-Bis(4-hydroxyphenyl)-1,6-heptadiene-3,5-dione",
    Synonyms: null,
    CASNumber: "24939-16-0",
    MolecularFormula: "C19H16O4",
    MolecularWeight: 308.33,
    Appearance: "Yellow orange colored powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=CC=C(/C=C/C(CC(/C=C/C2=CC=C(O)C=C2)=O)=O)C=C1",
    InChICode:
      "InChI=1S/C19H16O4/c20-16-7-1-14(2-8-16)5-11-18(22)13-19(23)12-6-15-3-9-17(21)10-4-15/h1-12,20-21H,13H2/b11-5+,12-6+",
    InChIKey: "PREBVFJICNPEKM-YDWXAUTNSA-N",
    PubChemCID: 5315472,
    MDLNumber: "MFCD03419284",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 26,
    Image: null,
    Product: "Boswellic acids (α+β)",
    BotanicalSource: "Boswellia serrata",
    IUPACName: null,
    Synonyms: null,
    CASNumber: "471-66-9(α) 631-69-6(β)",
    MolecularFormula: "C30H48O3",
    MolecularWeight: 456.73,
    Appearance: "Off White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in milligrams",
    type: "inStock",
  },
  {
    id: 27,
    Image: "/products/Camptothecine-7689-03-4.jpg",
    Product: "Camptothecine",
    BotanicalSource: "Mappia foetida",
    IUPACName:
      "(4S)-4-ethyl-4-hydroxy-1H-pyrano[3',4':6,7]indolizino[1,2-b]quinoline-3,14(4H,12H)-dione",
    Synonyms: null,
    CASNumber: "7689-03-4",
    MolecularFormula: "C20H16N2O4",
    MolecularWeight: 348.35,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C([C@@]1(CC)O)OCC2=C1C=C(C(N=C(C=CC=C3)C3=C4)=C4C5)N5C2=O",
    InChICode:
      "InChI=1S/C20H16N2O4/c1-2-20(25)14-8-16-17-12(7-11-5-3-4-6-15(11)21-17)9-22(16)18(23)13(14)10-26-19(20)24/h3-8,25H,2,9-10H2,1-5H3/b7-3+,8-4+",
    InChIKey: "VSJKWCGYPAHWDS-FQEVSTJZSA-N",
    PubChemCID: 24360,
    MDLNumber: "MFCD00081076",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 28,
    Image: "/products/Curcumin-458-37-7.jpg",
    Product: "Curcumin",
    BotanicalSource: "Curcuma longa",
    IUPACName:
      "(E,E)-1,7-bis(4-Hydroxy-3-methoxyphenyl)-1,6-heptadiene-3,5-dione",
    Synonyms: "Diferuloylmethane",
    CASNumber: "458-37-7",
    MolecularFormula: "C21H20O6",
    MolecularWeight: 368.38,
    Appearance: "Yellow colored powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COc1cc(/C=C/C(=O)CC(=O)/C=C/c2ccc(O)c(OC)c2)ccc1O",
    InChICode:
      "InChI=1S/C21H20O6/c1-26-20-11-14(5-9-18(20)24)3-7-16(22)13-17(23)8-4-15-6-10-19(25)21(12-15)27-2/h3-12,24-25H,13H2,1-2H3/b7-3+,8-4+",
    InChIKey: "VFLDPWHFBUODDF-FCXRPNKRSA-N",
    PubChemCID: 969516,
    MDLNumber: "MFCD00008365",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 29,
    Image: "/products/Demethoxycurcumin-22608-11-3.jpg",
    Product: "Demethoxycurcumin",
    BotanicalSource: "Curcuma longa",
    IUPACName:
      "(E,E)-1-(4-Hydroxy-3-methoxyphenyl)-7-(4-hydroxyphenyl)-1,6-heptadiene-3,5-dione",
    Synonyms: "Desmethoxycurcumin",
    CASNumber: "22608-11-3",
    MolecularFormula: "C20H18O5",
    MolecularWeight: 338.35,
    Appearance: "Yellow colored powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=CC=C(/C=C/C(CC(/C=C/C2=CC(OC)=C(O)C=C2)=O)=O)C=C1",
    InChICode:
      "InChI=1S/C20H18O5/c1-25-20-12-15(6-11-19(20)24)5-10-18(23)13-17(22)9-4-14-2-7-16(21)8-3-14/h2-12,21,24H,13H2,1H3/b9-4+,10-5+",
    InChIKey: "HJTVQHVGMGKONQ-LUZURFALSA-N",
    PubChemCID: 5469424,
    MDLNumber: "MFCD03427310",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 30,
    Image: "/products/Demethylwedelolactone-6468-55-9.jpg",
    Product: "Demethylwedelolactone",
    BotanicalSource: "Eclipta alba",
    IUPACName: "1,3,8,9-Tetrahydroxy-6H-benzofuro[3,2-c][1]benzopyran-6-one",
    Synonyms: "Desmethylwedelolactone",
    CASNumber: "6468-55-9",
    MolecularFormula: "C15H8O7",
    MolecularWeight: 300.22,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=CC2=C(OC(C(C(O)=CC(O)=C3)=C3O4)=C2C4=O)C=C1O",
    InChICode:
      "InChI=1S/C15H8O7/c16-5-1-9(19)13-11(2-5)22-15(20)12-6-3-7(17)8(18)4-10(6)21-14(12)13/h1-4,16-19H",
    InChIKey: "LUTYTNLPIUCKBJ-UHFFFAOYSA-N",
    PubChemCID: 5489605,
    MDLNumber: "MFCD09264645",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 31,
    Image: "/products/Embelin-550-24-3.jpg",
    Product: "Embelin",
    BotanicalSource: "Embelia ribes",
    IUPACName: "2,5-Dihydroxy-3-undecyl-2,5-cyclohexadiene-1,4-dione",
    Synonyms: "Emberine",
    CASNumber: "550-24-3",
    MolecularFormula: "C17H26O4",
    MolecularWeight: 294.39,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CCCCCCCCCCCC1=C(O)C(=O)C=C(O)C1=O",
    InChICode:
      "1S/C17H26O4/c1-2-3-4-5-6-7-8-9-10-11-13-16(20)14(18)12-15(19)17(13)21/h12,18,21H,2-11H2,1H3",
    InChIKey: "IRSFLDGTOHBADP-UHFFFAOYSA-N",
    PubChemCID: 3218,
    MDLNumber: "MFCD00016369",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 32,
    Image: "/products/Epoxyazadiradione-18385-59-6.jpg",
    Product: "Epoxyazadiradione",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "[(1S,2R,4S,6S,7S,10R,11R,16R,18R)-6-(furan-3-yl)-1,7,11,15,15-pentamethyl-5,14-dioxo-3-oxapentacyclo[8.8.0.02,4.02,7.011,16]octadec-12-en-18-yl] acetate",
    Synonyms: "Nimbinin",
    CASNumber: "18385-59-6",
    MolecularFormula: "C28H34O6",
    MolecularWeight: 466.6,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=O)O[C@@H]1C[C@@H]2[C@](C=CC(=O)C2(C)C)([C@@H]3[C@@]1([C@]45[C@H](O4)C(=O)[C@H]([C@@]5(CC3)C)C6=COC=C6)C)C",
    InChICode:
      "1S/C28H34O6/c1-15(29)33-20-13-18-24(2,3)19(30)8-10-25(18,4)17-7-11-26(5)21(16-9-12-32-14-16)22(31)23-28(26,34-23)27(17,20)6/h8-10,12,14,17-18,20-21,23H,7,11,13H2,1-6H3/t17-,18+,20-,21-,23-,25-,26+,27+,28-/m1/s1",
    InChIKey: "NEYCGDYQBQONFC-GGPFZBFUSA-N",
    PubChemCID: 49863985,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 33,
    Image: "/products/Forskolin-66575-29-9.jpg",
    Product: "Forskolin",
    BotanicalSource: "Coleus forskohlii",
    IUPACName: "7β-Acetoxy-8,13-epoxy-1α,6β,9α-trihydroxylabd-14-en-11-one",
    Synonyms: "Coleonol",
    CASNumber: "66575-29-9",
    MolecularFormula: "C22H34O7",
    MolecularWeight: 410.5,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@@](C1)(C=C)O[C@]2(C)[C@@H](OC(C)=O)[C@@H](O)[C@@]3([H])C(C)(C)CC[C@H](O)[C@]3(C)[C@@]2(O)C1=O",
    InChICode:
      "1S/C22H34O7/c1-8-19(5)11-14(25)22(27)20(6)13(24)9-10-18(3,4)16(20)15(26)17(28-12(2)23)21(22,7)29-19/h8,13,15-17,24,26-27H,1,9-11H2,2-7H3/t13-,15-,16-,17-,19-,20-,21+,22-/m0/s1",
    InChIKey: "OHCQJHSOBUTRHG-KGGHGJDLSA-N",
    PubChemCID: 47936,
    MDLNumber: "MFCD00082317",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 34,
    Image: "/products/Gedunin-2753-30-2.jpg",
    Product: "Gedunin",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "(1S,3aS,4aR,4bS,5R,6aR,10aR,10bR,12aS)-5-(acetyloxy)-1-(3-furanyl)-1,5,6,6a,7,10a,10b,11,12,12a-decahydro-4b,7,7,10a,12a-pentamethyl-oxireno[c]phenanthro[1,2-d]pyran-3,8(3aH,4bH)-dione",
    Synonyms: "NSC 113497",
    CASNumber: "2753-30-2",
    MolecularFormula: "C28H34O7",
    MolecularWeight: 482.57,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C4[C@@H]6[C@]3(O6)[C@]2(C)[C@H](OC(C)=O)C[C@@]1([H])C(C)(C)C(C=C[C@@](C)1[C@]([H])2CC[C@](C)3[C@H]([C@]5=COC=C5)O4)=O",
    InChICode:
      "1S/C28H34O7/c1-15(29)33-20-13-18-24(2,3)19(30)8-10-25(18,4)17-7-11-26(5)21(16-9-12-32-14-16)34-23(31)22-28(26,35-22)27(17,20)6/h8-10,12,14,17-18,20-22H,7,11,13H2,1-6H3/t17-,18+,20-,21+,22-,25-,26+,27+,28-/m1/s1",
    InChIKey: "YJXDGWUNRYLINJ-BHAPSIHVSA-N",
    PubChemCID: 12004512,
    MDLNumber: "MFCD01750174",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 35,
    Image: null,
    Product: "Guggulsterones (E+Z)",
    BotanicalSource: "Commiphora mukul",
    IUPACName: null,
    Synonyms: null,
    CASNumber: "39025-24-6(E) 39025-23-5(Z)",
    MolecularFormula: "C21H28O2",
    MolecularWeight: 312.45,
    Appearance: "Pale  yellow liquid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in milligrams",
    type: "inStock",
  },
  {
    id: 36,
    Image: "/products/Guggulsterone-Z-39025-23-5.jpg",
    Product: "Guggulsterone-Z",
    BotanicalSource: "Commiphora mukul",
    IUPACName: "(17Z)-Pregna-4,17(20)-diene-3,16-dione",
    Synonyms: null,
    CASNumber: "39025-23-5",
    MolecularFormula: "C21H28O2",
    MolecularWeight: 312.45,
    Appearance: "White amorphous powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C/C=C1\\C(=O)CC2C3CCC4=CC(=O)CC[C@]4(C)C3CC[C@]\\12C",
    InChICode:
      "InChI=1S/C21H28O2/c1-4-16-19(23)12-18-15-6-5-13-11-14(22)7-9-20(13,2)17(15)8-10-21(16,18)3/h4,11,15,17-18H,5-10,12H2,1-3H3/b16-4+/t15?,17?,18?,20-,21+/m0/s1",
    InChIKey: "WDXRGPWQVHZTQJ-XCTJHIRPSA-N",
    PubChemCID: 6450278,
    MDLNumber: "MFCD01310757",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 37,
    Image: "/products/Guggulsterone-E-39025-24-6.jpg",
    Product: "Guggulsterone-E",
    BotanicalSource: "Commiphora mukul",
    IUPACName: "(17E)-Pregna-4,17(20)-diene-3,16-dione",
    Synonyms: "Trans-Guggulsterone",
    CASNumber: "39025-24-6",
    MolecularFormula: "C21H28O2",
    MolecularWeight: 312.45,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1CC[C@@]2(C)C(CC[C@]3([H])[C@]2([H])CC[C@@]4(C)[C@@]3([H])CC(/C4=CC)=O)=C1",
    InChICode:
      "InChI=1S/C21H28O2/c1-4-16-19(23)12-18-15-6-5-13-11-14(22)7-9-20(13,2)17(15)8-10-21(16,18)3/h4,11,15,17-18,20-21,23H,5-10,12H2,1-3H3/b16-4+/t15-,17+,18+,20+,21-/m1/s1",
    InChIKey: "WDXRGPWQVHZTQJ-OSJVMJFVSA-N",
    PubChemCID: 6439929,
    MDLNumber: "MFCD01310758",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 38,
    Image: "/products/Hypophyllanthin-33676-00-5.jpg",
    Product: "Hypophyllanthin",
    BotanicalSource: "Phyllanthus amarus",
    IUPACName:
      "(7S,8S,9R)-9-(3,4-dimethoxyphenyl)-6,7,8,9-tetrahydro-4-methoxy-7,8-bis(methoxymethyl)-naphtho[1,2-d]-1,3-dioxole",
    Synonyms: "NSC 619044",
    CASNumber: "33676-00-5",
    MolecularFormula: "C24H30O7",
    MolecularWeight: 430.49,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "COC[C@@H]1[C@@H](COC)CC(C=C(OC)C2=C3OCO2)=C3[C@H]1C4=CC(OC)=C(OC)C=C4",
    InChICode:
      "InChI=1S/C24H30O7/c1-25-11-16-8-15-10-20(29-5)23-24(31-13-30-23)22(15)21(17(16)12-26-2)14-6-7-18(27-3)19(9-14)28-4/h6-7,9-10,16-17,21H,8,11-13H2,1-5H3/t16-,17-,21+/m1/s1",
    InChIKey: "LBJCUHLNHSKZBW-LZJOCLMNSA-N",
    PubChemCID: 182140,
    MDLNumber: "MFCD03424459",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 39,
    Image: "/products/Isoalantolactone-470-17-7.jpg",
    Product: "Isoalantolactone",
    BotanicalSource: "Inula racemosa",
    IUPACName:
      "(3aR,4aS,8aR,9aR)-decahydro-8a-methyl-3,5-bis(methylene)-naphtho[2,3-b]furan-2(3H)-one",
    Synonyms: "(+)-Isoalantolactone",
    CASNumber: "470-17-7",
    MolecularFormula: "C15H20O2",
    MolecularWeight: 232.32,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C[C@]12[C@@](C(CCC2)=C)([H])C[C@@]3([H])[C@@](OC(C3=C)=O)([H])C1",
    InChICode:
      "InChI=1S/C15H20O2/c1-9-5-4-6-15(3)8-13-11(7-12(9)15)10(2)14(16)17-13/h11-13H,1-2,4-8H2,3H3/t11-,12+,13-,15-/m1/s1",
    InChIKey: "CVUANYCQTOGILD-QVHKTLOISA-N",
    PubChemCID: 73285,
    MDLNumber: "MFCD08689940",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 40,
    Image: "/products/Isoandrographolide-0.jpg",
    Product: "Isoandrographolide",
    BotanicalSource: "Andrographis paniculata",
    IUPACName:
      "3-[(3aR,5aS,6R,7R,9aR,9bS)-7-hydroxy-6-(hydroxymethyl)-3a,6,9a-trimethyl-dodecahydronaphtho[2,1-b]furan-2-yl]-2,5-dihydrofuran-2-one",
    Synonyms: null,
    CASNumber: null,
    MolecularFormula: "C20H30O5",
    MolecularWeight: 350.45,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@@]12CC[C@H]3[C@@](C)(CC[C@@H](O)[C@@]3(C)CO)[C@@H]1CC(O2)C1=CCOC1=O",
    InChICode:
      "InChI=1S/C20H30O5/c1-18-7-5-16(22)19(2,11-21)14(18)4-8-20(3)15(18)10-13(25-20)12-6-9-24-17(12)23/h6,13-16,21-22H,4-5,7-11H2,1-3H3/t13?,14-,15-,16+,18+,19-,20+/m0/s1",
    InChIKey: "QTYVPMSAPQBXMM-BXTHMLGCSA-N",
    PubChemCID: 101243415,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 41,
    Image: "/products/Isomeldenin-186892-52-4.jpg",
    Product: "Isomeldenin",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "[(5R,6R,8R,9R,10R,13S,17R)-17-(furan-3-yl)-6-hydroxy-4,4,8,10,13-pentamethyl-3-oxo-1,2,5,6,7,9,11,12,16,17-decahydrocyclopenta[a]phenanthren-7-yl] acetate",
    Synonyms: "Isomeldenin",
    CASNumber: "186892-52-4",
    MolecularFormula: "C28H38O5",
    MolecularWeight: 454.6,
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=O)OC1[C@@H]([C@@H]2[C@](CCC(=O)C2(C)C)([C@@H]3[C@@]1(C4=CC[C@H]([C@@]4(CC3)C)C5=COC=C5)C)C)O",
    InChICode:
      "1S/C28H38O5/c1-16(29)33-24-22(31)23-25(2,3)21(30)10-13-27(23,5)20-9-12-26(4)18(17-11-14-32-15-17)7-8-19(26)28(20,24)6/h8,11,14-15,18,20,22-24,31H,7,9-10,12-13H2,1-6H3/t18-,20+,22+,23-,24?,26-,27+,28-/m0/s1",
    InChIKey: "KRGKDQUGJXDINQ-GOSWNXTOSA-N",
    PubChemCID: "76316558",
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 42,
    Image: "/products/Lupeol-545-47-1.jpg",
    Product: "Lupeol",
    BotanicalSource: "Crataeva nurvala",
    IUPACName: "20(29)-Lupen-3β-ol, 3β-Hydroxy-20(29)-lupene, Fagarasterol",
    Synonyms:
      "Clerodol; Fagarasterol; Lupenol; Monogynol B; NSC 90487; β-Viscol",
    CASNumber: "545-47-1",
    MolecularFormula: "C30H50O",
    MolecularWeight: 426.72,
    Appearance: "Solid powder",
    Purity: ">98 %",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC1(C)[C@@H](O)CC[C@]2(C)[C@@]3([H])CC[C@]4([H])[C@@]5([H])[C@H](C(C)=C)CC[C@@](C)5CC[C@](C)4[C@@](C)3CC[C@@]12[H]",
    InChICode:
      "1S/C30H50O/c1-19(2)20-11-14-27(5)17-18-29(7)21(25(20)27)9-10-23-28(6)15-13-24(31)26(3,4)22(28)12-16-30(23,29)8/h20-25,31H,1,9-18H2,2-8H3/t20-,21+,22-,23+,24-,25+,27+,28-,29+,30+/m0/s1",
    InChIKey: "MQYXUWHLBZFQQO-QGTGJCAVSA-N",
    PubChemCID: 259846,
    MDLNumber: "MFCD00017351",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 43,
    Image: "/products/Lysergol-602-85-7.jpg",
    Product: "Lysergol",
    BotanicalSource: "Ipomoea hederacea",
    IUPACName: "9,10-didehydro-6-methyl-ergoline-8β-methanol",
    Synonyms: "Lysergole",
    CASNumber: "602-85-7",
    MolecularFormula: "C16H18N2O",
    MolecularWeight: 254.3,
    Appearance: "Off white solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CN1[C@](C2=C[C@@H](CO)C1)([H])CC3=CNC4=CC=CC2=C34",
    InChICode:
      "InChI=1S/C16H18N2O/c1-18-8-10(9-19)5-13-12-3-2-4-14-16(12)11(7-17-14)6-15(13)18/h2-5,7,10,15,17,19H,6,8-9H2,1H3/t10-,15-/m1/s1",
    InChIKey: "BIXJFIJYBLJTMK-MEBBXXQBSA-N",
    PubChemCID: 14987,
    MDLNumber: "MFCD00010029",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 44,
    Image: "/products/Neoandrographolide-27215-14-1.jpg",
    Product: "Neoandrographolide",
    BotanicalSource: "Andrographis paniculata",
    IUPACName:
      "ent-19-Hydroxy-8(17),13-labdadien-16,15-olide 19-O-β-D-glucopyranoside",
    Synonyms: "Neoandrographiside",
    CASNumber: "27215-14-1",
    MolecularFormula: "C26H40O8",
    MolecularWeight: 480.6,
    Appearance: "White solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@]12[C@@](CCC([C@H]2CCC3=CCOC3=O)=C)([H])[C@@](CO[C@@H]4O[C@H](CO)[C@@H](O)[C@H](O)[C@H]4O)(C)CCC1",
    InChICode:
      "InChI=1S/C26H40O8/c1-15-5-8-19-25(2,14-33-24-22(30)21(29)20(28)18(13-27)34-24)10-4-11-26(19,3)17(15)7-6-16-9-12-32-23(16)31/h9,17-22,24,27-30H,1,4-8,10-14H2,2-3H3/t17-,18-,19-,20-,21+,22-,24-,25+,26+,27+/m1/s1",
    InChIKey: "YGCYRQKJYWQXHG-RDNQFMDVSA-N",
    PubChemCID: 9848024,
    MDLNumber: "MFCD07779130",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 45,
    Image: "/products/Nimbolide-25990-37-8.jpg",
    Product: "Nimbolide",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "(4α,5α,6α,7α,15β,17α)-7,15:21,23-Diepoxy-6-hydroxy-4,8-dimethyl-1-oxo-18,24-dinor-11,12-secochola-2,13,20,22-tetraene-4,11-dicarboxylic Αcid γ-lactone Methyl Ester",
    Synonyms: "NSC 309909",
    CASNumber: "25990-37-8",
    MolecularFormula: "C27H30O7",
    MolecularWeight: 466.52,
    Appearance: "Off white powder",
    Purity: ">98 %",
    HRMS_ESI: "m/z 467.20 [M]+",
    SpecificRotation_aD20: null,
    SMILES:
      "CC1=C2[C@](CC1C3=COC=C3)([H])O[C@]([C@]2(C)[C@@H]4CC(OC)=O)([H])[C@@]5([H])C6[C@]4(C)C(C=C[C@@]6(C)C(O5)=O)=O",
    InChICode:
      "InChI=1S/C27H30O7/c1-13-15(14-7-9-32-12-14)10-16-20(13)27(4)17(11-19(29)31-5)26(3)18(28)6-8-25(2)22(26)21(23(27)33-16)34-24(25)30/h6-9,12,15-17,21-23H,10-11,13-15H2,1-5H3/t15?,16-,17-,21-,22?,23-,25-,26+,27-/m1/s1",
    InChIKey: "JZIQWNPPBKFOPT-FTCZZEDLSA-N",
    PubChemCID: 100017,
    MDLNUMBER: "MFCD01735386",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 46,
    Image: "/products/Nimocinol-(Nimonol)-95260-96-1.jpg",
    Product: "Nimocinol (Nimonol)",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "[(5R,6R,7S,9R,10R,13S,17R)-17-(furan-3-yl)-6-hydroxy-4,4,8,10,13-pentamethyl-3-oxo-5,6,7,9,11,12,16,17-octahydrocyclopenta[a]phenanthren-7-yl] acetate",
    Synonyms: "•	(+)-Nimocinol",
    CASNumber: "95260-96-1",
    MolecularFormula: "C28H36O5",
    MolecularWeight: 452.6,
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=O)O[C@@H]1[C@@H]([C@@H]2[C@](C=CC(=O)C2(C)C)([C@@H]3C1(C4=CC[C@H]([C@@]4(CC3)C)C5=COC=C5)C)C)O",
    InChICode:
      "1S/C28H36O5/c1-16(29)33-24-22(31)23-25(2,3)21(30)10-13-27(23,5)20-9-12-26(4)18(17-11-14-32-15-17)7-8-19(26)28(20,24)6/h8,10-11,13-15,18,20,22-24,31H,7,9,12H2,1-6H3/t18-,20+,22+,23-,24+,26-,27+,28?/m0/s1",
    InChIKey: "GDMYRVKWBYREMU-SAGQAIRCSA-N",
    PubChemCID: 178770,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 47,
    Image: "/products/Nimolicinol-89366-02-9.jpg",
    Product: "Nimolicinol",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "[(1S,4bR,5R,6aR,10aR,10bR,12aR)-1-(furan-3-yl)-1-hydroxy-4b,7,7,10a,12a-pentamethyl-3,8-dioxo-5,6,6a,10b,11,12-hexahydronaphtho[2,1-f]isochromen-5-yl] acetate",
    Synonyms: "NSC 382016",
    CASNumber: "89366-02-9",
    MolecularFormula: "C28H34O7",
    MolecularWeight: 482.6,
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=O)O[C@@H]1C[C@@H]2[C@](C=CC(=O)C2(C)C)([C@@H]3[C@@]1(C4=CC(=O)O[C@@]([C@@]4(CC3)C)(C5=COC=C5)O)C)C",
    InChICode:
      "1S/C28H34O7/c1-16(29)34-22-13-19-24(2,3)21(30)8-10-25(19,4)18-7-11-26(5)20(27(18,22)6)14-23(31)35-28(26,32)17-9-12-33-15-17/h8-10,12,14-15,18-19,22,32H,7,11,13H2,1-6H3/t18-,19+,22-,25-,26-,27-,28-/m1/s1",
    InChIKey: "VXKRRVRNHBWLTO-VBPYNQNZSA-N",
    PubChemCID: "184937",
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    Analogues: "Available",
    type: "inStock",
  },
  {
    id: 48,
    Image: "/products/Niranthin-50656-77-4.jpg",
    Product: "Niranthin",
    BotanicalSource: "Phyllanthus amarus",
    IUPACName:
      "6-[(2R,3R)-3-[(3,4-dimethoxyphenyl)methyl]-4-methoxy-2-(methoxymethyl)butyl]-4-methoxy-1,3-benzodioxole",
    Synonyms: null,
    CASNumber: "50656-77-4",
    MolecularFormula: "C24H32O7",
    MolecularWeight: 432.51,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COC1=C(OCO2)C2=CC(C[C@H](COC)[C@@H](COC)CC3=CC=C(OC)C(OC)=C3)=C1",
    InChICode:
      "1S/C24H32O7/c1-25-13-18(8-16-6-7-20(27-3)21(10-16)28-4)19(14-26-2)9-17-11-22(29-5)24-23(12-17)30-15-31-24/h6-7,10-12,18-19H,8-9,13-15H2,1-5H3/t18-,19-/m0/s1",
    InChIKey: "RCFGIEPQSDGMJJ-OALUTQOASA-N",
    PubChemCID: 13989915,
    MDLNumber: "MFCD09970365",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 49,
    Image: "/products/Phyllanthin-10351-88-9.jpg",
    Product: "Phyllanthin",
    BotanicalSource: "Phyllanthus amarus",
    IUPACName:
      "1,1’-[(2S,3S)-2,3-bis(methoxymethyl)-1,4-butanediyl]bis[3,4-dimethoxy-benzene",
    Synonyms: "NSC 619043",
    CASNumber: "10351-88-9",
    MolecularFormula: "C24H34O6",
    MolecularWeight: 418.52,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "COC1=C(OC)C=C(C[C@]([C@@](CC2=CC=C(OC)C(OC)=C2)([H])COC)([H])COC)C=C1",
    InChICode:
      "InChI=1S/C24H34O6/c1-25-15-19(11-17-7-9-21(27-3)23(13-17)29-5)20(16-26-2)12-18-8-10-22(28-4)24(14-18)30-6/h7-10,13-14,19-20H,11-12,15-16H2,1-6H3/t19-,20-/m1/s1",
    InChIKey: "KFLQGJQSLUYUBF-WOJBJXKFSA-N",
    PubChemCID: 358901,
    MDLNumber: "MFCD17166965",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 50,
    Image: "/products/Piperin-94-62-2.jpg",
    Product: "Piperin",
    BotanicalSource: "Piper nigrum",
    IUPACName:
      "(2E,4E)-5-(1,3-benzodioxol-5-yl)-1-(1-piperidinyl)-2,4-pentadien-1-one",
    Synonyms: "Bioperine",
    CASNumber: "94-62-2",
    MolecularFormula: "C17H19NO3",
    MolecularWeight: 285.34,
    Appearance: "White crystalline powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C(/C=C/C=C/C1=CC(OCO2)=C2C=C1)N3CCCCC3",
    InChICode:
      "InChI=1S/C17H19NO3/c19-17(18-10-4-1-5-11-18)7-3-2-6-14-8-9-15-16(12-14)21-13-20-15/h2-3,6-9,12H,1,4-5,10-11,13H2/b6-2+,7-3+",
    InChIKey: "MXXWOMGUGJBKIW-YPCIICBESA-N",
    PubChemCID: 638024,
    MDLNumber: "MFCD00005839",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 51,
    Image: "/products/Piperlonguminine-5950-12-9.jpg",
    Product: "Piperlonguminine",
    BotanicalSource: "Piper longum",
    IUPACName:
      "(2E,4E)-5-(1,3-benzodioxol-5-yl)-N-(2-methylpropyl)penta-2,4-dienamide",
    Synonyms: "N-Isobutylpiperamide",
    CASNumber: "5950-12-9",
    MolecularFormula: "C16H19NO3",
    MolecularWeight: 273.33,
    Appearance: "White solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC(C)CNC(=O)C=CC=CC1=CC2=C(C=C1)OCO2",
    InChICode:
      "InChI=1S/C16H19NO3/c1-12(2)10-17-16(18)6-4-3-5-13-7-8-14-15(9-13)20-11-19-14/h3-9,12H,10-11H2,1-2H3,(H,17,18)/b5-3+,6-4+",
    InChIKey: "WHAAPCGHVWVUEX-GGWOSOGESA-N",
    PubChemCID: 5320621,
    MDLNumber: "MFCD15146947",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 52,
    Image: "/products/Psoralen-66-97-7.jpg",
    Product: "Psoralen",
    BotanicalSource: "Psoralea corylifolia",
    IUPACName: "7H-furo[3,2-g][1]benzopyran-7-one",
    Synonyms: "Ficusin",
    CASNumber: "66-97-7",
    MolecularFormula: "C11H6O3",
    MolecularWeight: 186.2,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C=CC2=CC3=C(OC=C3)C=C2O1",
    InChICode:
      "InChI=1S/C11H6O3/c12-11-2-1-7-5-8-3-4-13-9(8)6-10(7)14-11/h1-6H",
    InChIKey: "ZCCUUQDIBDJBTK-UHFFFAOYSA-N",
    PubChemCID: 6199,
    MDLNumber: "MFCD00010520",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 53,
    Image: "/products/Pterostilbene-537-42-8.jpg",
    Product: "Pterostilbene",
    BotanicalSource: "Pterocarpus marsupium",
    IUPACName: "4-[(1E)-2-(3,5-dimethoxyphenyl)ethenyl]-phenol",
    Synonyms: "trans-3,5-Dimethoxy-4'-Hydroxystilbene",
    CASNumber: "537-42-8",
    MolecularFormula: "C16H16O3",
    MolecularWeight: 256.3,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COc1cc(/C=C\\c2ccc(O)cc2)cc(OC)c1",
    InChICode:
      "InChI=1S/C16H16O3/c1-18-15-9-13(10-16(11-15)19-2)4-3-12-5-7-14(17)8-6-12/h3-11,17H,1-2H3/b4-3+",
    InChIKey: "VLEUZFDZJKSGMX-ONEGZZNKSA-N",
    PubChemCID: 5281727,
    MDLNumber: "MFCD00238710",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 54,
    Image: "/products/Rhein-478-43-3.jpg",
    Product: "Rhein",
    BotanicalSource: "Aloe barbadensis",
    IUPACName:
      "9,10-dihydro-4,5-dihydroxy-9,10-dioxo-2-anthracenecarboxylic acid",
    Synonyms: "Rheic Acid",
    CASNumber: "478-43-3",
    MolecularFormula: "C15H8O6",
    MolecularWeight: 284.22,
    Appearance: "Crystalline solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C2=C(C=C(C(O)=O)C=C2O1)C(C3=CC=CC(O)=C31)=O",
    InChICode:
      "InChI=1S/C15H8O6/c16-9-3-1-2-7-11(9)14(19)12-8(13(7)18)4-6(15(20)21)5-10(12)17/h1-5,16-17H,(H,20,21)",
    InChIKey: "FCDLCPWAQCPTKC-UHFFFAOYSA-N",
    PubChemCID: 10168,
    MDLNumber: "MFCD00009618",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 55,
    Image: "/products/Rubiadin-117-02-2.jpg",
    Product: "Rubiadin",
    BotanicalSource: "Rubia cordifolia",
    IUPACName: "1,3-dihydroxy-2-methyl-9,10-anthracenedione",
    Synonyms: "C.I. 75350",
    CASNumber: "117-02-2",
    MolecularFormula: "C15H10O4",
    MolecularWeight: 254.24,
    Appearance: "Yellow colored solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C2=C(O)C(C)=C(O)C=C2C(C3=C1C=CC=C3)=O",
    InChICode:
      "InChI=1S/C15H10O4/c1-7-11(16)6-10-12(13(7)17)15(19)9-5-3-2-4-8(9)14(10)18/h2-6,16-17H,1H3",
    InChIKey: "IRZTUXPRIUZXMP-UHFFFAOYSA-N",
    PubChemCID: 124062,
    MDLNumber: "MFCD02752095",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 56,
    Image: "/products/Sesamin-0607-80-7.jpg",
    Product: "Sesamin",
    BotanicalSource: "Sesamum indicum",
    IUPACName:
      "[1S-(1α,3aα,4α,6aα)]-5,5'-(tetrahydro-1H,3H-furo[3,4-c]furan-1,4-diyl)bis-1,3-benzodioxole",
    Synonyms: "Fagarol",
    CASNumber: "607-80-7",
    MolecularFormula: "C20H18O6",
    MolecularWeight: 354.4,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C1OC2=CC=[C@H](C=C2O1)[C@H]1OC[C@@H]2[C@H](OC[C@H]12)c1ccc2OCOc2c1",
    InChICode:
      "InChI=1S/C20H18O6/c1-3-15-17(25-9-23-15)5-11(1)19-13-7-22-20(14(13)8-21-19)12-2-4-16-18(6-12)26-10-24-16/h1-6,13-14,19-20H,7-10H2/t13-,14-,19+,20+/m0/s1",
    InChIKey: "PEYUIKBAABKQKQ-AFHBHXEDSA-N",
    PubChemCID: 72307,
    MDLNumber: "MFCD00216134",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 57,
    Image: "/products/Sesamolin-526-07-8.jpg",
    Product: "Sesamolin",
    BotanicalSource: "Sesamum indicum",
    IUPACName:
      "5-[(1S,3aR,4R,6aR)-4-(1,3-Benzodioxol-5-yloxy)tetrahydro-1H,3H-furo[3,4-c]furan-1-yl]-1,3-benzodioxole",
    Synonyms: null,
    CASNumber: "526-07-8",
    MolecularFormula: "C20H18O7",
    MolecularWeight: 370.35,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[H][C@@]12[C@@H](C(C=C3)=CC4=C3OCO4)OC[C@]1([H])[C@@H](OC5=CC6=C(C=C5)OCO6)OC2",
    InChICode:
      "1S/C20H18O7/c1-3-15-17(25-9-23-15)5-11(1)19-13-7-22-20(14(13)8-21-19)27-12-2-4-16-18(6-12)26-10-24-16/h1-6,13-14,19-20H,7-10H2/t13-,14-,19+,20+/m0/s1",
    InChIKey: "ZZMNWJVJUKMZJY-AFHBHXEDSA-N",
    PubChemCID: "101746",
    MDLNumber: "MFCD04112844",
    InventoryStatus: "Available in gram quantities",
    Analogues: "Available",
    type: "inStock",
  },
  {
    id: 58,
    Image: "/products/Tetrahydrocurcumin-36062-04-1.jpg",
    Product: "Tetrahydrocurcumin",
    BotanicalSource: "Curcuma longa",
    IUPACName: "1,7-bis(4-hydroxy-3-methoxyphenyl)-3,5-heptanedione",
    Synonyms: "NSC 687845",
    CASNumber: "36062-04-1",
    MolecularFormula: "C21H24O6",
    MolecularWeight: 372.41,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=C(OC)C=C(CCC(CC(CCC2=CC=C(O)C(OC)=C2)=O)=O)C=C1",
    InChICode:
      "InChI=1S/C21H24O6/c1-26-20-11-14(5-9-18(20)24)3-7-16(22)13-17(23)8-4-15-6-10-19(25)21(12-15)27-2/h5-6,9-12,24-25H,3-4,7-8,13H2,1-2H3",
    InChIKey: "LBTVHXHERHESKG-UHFFFAOYSA-N",
    PubChemCID: 124072,
    MDLNumber: "MFCD04152347",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 59,
    Image: "/products/Tetrahydropiperin-23434-88-0.jpg",
    Product: "Tetrahydropiperin",
    BotanicalSource: "Piper nigrum",
    IUPACName: "5-(1,3-benzodioxol-5-yl)-1-(1-piperidinyl)-1-pentanone",
    Synonyms: "THP",
    CASNumber: "23434-88-0",
    MolecularFormula: "C17H23NO3",
    MolecularWeight: 289.37,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C(CCCCC1=CC(OCO2)=C2C=C1)N3CCCCC3",
    InChICode:
      "InChI=1S/C17H23NO3/c19-17(18-10-4-1-5-11-18)7-3-2-6-14-8-9-15-16(12-14)21-13-20-15/h8-9,12H,1-7,10-11,13H2",
    InChIKey: "APZYKUZPJCQGPP-UHFFFAOYSA-N",
    PubChemCID: 581676,
    MDLNumber: "MFCD17167032",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 60,
    Image: "/products/Vasicine-6159-55-3.jpg",
    Product: "Vasicine",
    BotanicalSource: "Adhatoda vasica",
    IUPACName: "(3S)-1,2,3,9-Tetrahydropyrrolo[2,1-b]quinazolin-3-ol",
    Synonyms: "(−)-Peganine",
    CASNumber: "6159-55-3",
    MolecularFormula: "C11H12N2O",
    MolecularWeight: 188.2,
    Appearance: "Off white soild",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O[C@H]1CCN2C1=NC(C=CC=C3)=C3C2",
    InChICode:
      "InChI=1S/C11H12N2O/c14-9-5-6-13-10(9)12-8-4-2-1-3-7(8)11(13)15/h1-4,10,14H,5-6H2/t10-/m0/s1",
    InChIKey: "YIICVSCAKJMMDJ-JTQLQIEISA-N",
    PubChemCID: 442929,
    MDLNumber: "MFCD09028068",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 61,
    Image: "/products/Vasicine-HCl-7174-27-8.jpg",
    Product: "Vasicine HCl",
    BotanicalSource: "Adhatoda vasica",
    IUPACName: "(3S)-1,2,3,9-Tetrahydropyrrolo[2,1-b]quinazolin-3-ol",
    Synonyms: "Peganine hydrochloride",
    CASNumber: "7174-27-8",
    MolecularFormula: "C11H12N2O.HCl",
    MolecularWeight: 224.69,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode:
      "1S/C11H12N2O.ClH/c14-10-5-6-13-7-8-3-1-2-4-9(8)12-11(10)13;/h1-4,10,14H,5-7H2;1H/t10-;/m0./s1",
    InChIKey: "CSDQSAHJCAKJPT-PPHPATTJSA-N",
    PubChemCID: null,
    MDLNumber: "MFCD01686833",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 62,
    Image: "/products/Vasicinone-486-64-6.jpg",
    Product: "Vasicinone",
    BotanicalSource: "Adhatoda vasica",
    IUPACName: "(3S)-2,3-Dihydro-3-hydroxypyrrolo[2,1-b]quinazolin-9(1H)-one",
    Synonyms: "(-)Vasicinone",
    CASNumber: "486-64-6",
    MolecularFormula: "C11H10N2O2",
    MolecularWeight: 202.2,
    Appearance: "Solid powder",
    Purity: ">96",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1N2C([C@@H](O)CC2)=NC3=CC=CC=C31",
    InChICode:
      "1S/C11H10N2O2/c14-9-5-6-13-10(9)12-8-4-2-1-3-7(8)11(13)15/h1-4,9,14H,5-6H2/t9-/m0/s1",
    InChIKey: "SDIVYZXRQHWCKF-VIFPVBQESA-N",
    PubChemCID: 10242,
    MDLNumber: "MFCD11519457",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 63,
    Image: "/products/WithaferinA-5119-48-2.jpg",
    Product: "Withaferin A",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "4β,5β,6β,22R)-5,6-Epoxy-4,22,27-trihydroxy-1-oxo-ergosta-2,24-dien-26-oic acid δ-lactone",
    Synonyms:
      "NSC 101088; NSC-101088; NSC101088; NSC 273757; NSC-273757; NSC273757.",
    CASNumber: "5119-48-2",
    MolecularFormula: "C28H38O6",
    MolecularWeight: 470.6,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: "m/z 471.45 [M]+",
    SpecificRotation_aD20: null,
    SMILES:
      "O=C/1O[C@H](CC(=C/1CO)C)[C@@H](C)[C@H]6CC[C@@H]4[C@]6(C)CC[C@@H]3[C@]5(C(=O)C=C/[C@H](O)[C@]52O[C@@H]2C[C@H]34)C",
    InChICode:
      "1S/C28H38O6/c1-14-11-21(33-25(32)17(14)13-29)15(2)18-5-6-19-16-12-24-28(34-24)23(31)8-7-22(30)27(28,4)20(16)9-10-26(18,19)3/h7-8,15-16,18-21,23-24,29,31H,5-6,9-13H2,1-4H3/t15-,16-,18+,19?,20-,21+,23-,24+,26+,27-,28+/m0/s1",
    InChIKey: "DBRXOUCRJQVYJQ-NPRZOXALSA-N",
    PubChemCID: 45489105,
    MDLNumber: "MFCD10687098",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 64,
    Image: "/products/WithanolideA-32911-62-9.jpg",
    Product: "Withanolide A",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "(5α,6α,7α,22R)-6,7-epoxy-5,20,22-trihydroxy-1-oxo-ergosta-2,24-dien-26-oic Acid δ-Lactone",
    Synonyms: "Withaniol",
    CASNumber: "32911-62-9",
    MolecularFormula: "C28H38O6",
    MolecularWeight: 470.6,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C(O1)C(C)=C(C)C[C@]1([H])[C@@](O)(C)[C@H]2CC[C@@]3([H])[C@]4([H])[C@H]5[C@H](O5)[C@@]([C@@]6(C)[C@@]4([H])CC[C@@]32C)(O)CC=CC6=O",
    InChICode:
      "1S/C28H38O6/c1-14-13-20(33-24(30)15(14)2)27(5,31)18-9-8-16-21-17(10-12-25(16,18)3)26(4)19(29)7-6-11-28(26,32)23-22(21)34-23/h6-7,16-18,20-23,31-32H,8-13H2,1-",
    InChIKey:
      "5H3/t16-,17-,18-,20+,21-,22-,23-,25-,26-,27+,28-/m0/s1DXWHOKCXBGLTMQ-SFQAJKIESA-N",
    PubChemCID: 11294368,
    MDLNumber: "MFCD07779143",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 65,
    Image: "/products/WithanolideB-56973-41-2.jpg",
    Product: "Withanolide B",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "6α,7α-epoxy-5α,22R-dihydroxy-1-oxo-ergosta-2,24-dien-26-oic acid, δ-lactone",
    Synonyms: "Withanolide B",
    CASNumber: "56973-41-2",
    MolecularFormula: "C28H38O5",
    MolecularWeight: 454.6,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1[C@@]2(C)[C@]([C@@H](O3)[C@@H]3[C@]4([H])[C@]2([H])CC[C@@]5(C)[C@@]4([H])CC[C@@H]5[C@H](C)[C@@]6(OC(C(C)=C(C)C6)=O)[H])(O)CC=C1",
    InChICode:
      "1S/C28H38O5/c1-14-13-20(32-25(30)15(14)2)16(3)17-8-9-18-22-19(10-12-26(17,18)4)27(5)21(29)7-6-11-28(27,31)24-23(22)33-24/h6-7,16-20,22-24,31H,8-13H2,1-5H3/t16-,17+,18-,19-,20+,22-,23-,24-,26+,27-,28-/m0/s1",
    InChIKey: "ZTEVDTFJUUJBLP-MBMSZCMESA-N",
    PubChemCID: 14236711,
    MDLNumber: "MFCD19053156",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 66,
    Image: "/products/Withanone-27570-38-3.jpg",
    Product: "Withanone",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "(22R)-6α,7α-Epoxy-5,17,22-trihydroxy-1-oxo-5α-ergosta-2,24-dien-26-oic acid δ-lactone",
    Synonyms: "NSC 179884",
    CASNumber: "27570-38-3",
    MolecularFormula: "C28H38O6",
    MolecularWeight: 470.6,
    Appearance: "Withanolide B",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1C=CC[C@]2(O)[C@@]3([H])O[C@@]3([H])[C@@]4([H])[C@]5([H])CC[C@](O)([C@@H]([C@H]6CC(C)=C(C)C(O6)=O)C)[C@@]5(C)CC[C@]4([H])[C@@]12C",
    InChICode:
      "1S/C28H38O6/c1-14-13-19(33-24(30)15(14)2)16(3)27(31)12-9-17-21-18(8-11-25(17,27)4)26(5)20(29)7-6-10-28(26,32)23-22(21)34-23/h6-7,16-19,21-23,31-32H,8-13H2,1-5H3/t16-,17+,18+,19-,21+,22+,23+,25+,26+,27+,28+/m1/s1",
    InChIKey: "FAZIYUIDUNHZRG-PCTWTJKKSA-N",
    PubChemCID: 21679027,
    MDLNumber: "MFCD09264639",
    InventoryStatus: "Available in gram quantities",
    type: "inStock",
  },
  {
    id: 67,
    Image: "/products/8-Deoxygartanin-33390-41-9.jpg",
    Product: "8-Deoxygartanin",
    BotanicalSource: "Garcinia mangostana",
    IUPACName:
      "1,3,5-trihydroxy-2,4-bis(3-methyl-2-buten-1-yl)-9H-xanthen-9-one",
    Synonyms: null,
    CASNumber: "33390-41-9",
    MolecularFormula: "C23H24O5",
    MolecularWeight: 380.4,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C2=C(OC3=C1C(O)=C(C/C=C(C)/C)C(O)=C3C/C=C(C)/C)C(O)=CC=C2",
    InChICode:
      "1S/C23H24O5/c1-12(2)8-10-14-19(25)16(11-9-13(3)4)23-18(20(14)26)21(27)15-6-5-7-17(24)22(15)28-23/h5-9,24-26H,10-11H2,1-4H3",
    InChIKey: "GVQOVMKBYJKZSY-UHFFFAOYSA-N",
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Under Processing",
    Analogues: "Available",
    type: "underProcessing",
  },
  {
    id: 68,
    Image: "/products/20-Hydroxyecdysone-5289-74-7.jpg",
    Product: "20-Hydroxy ecdysone",
    BotanicalSource: "Ipomoea hederacea",
    IUPACName: "(5β)-2β,3β,14,20,22R,25-hexahydroxy-cholest-7-en-6-one",
    Synonyms: "Ecdysterone",
    CASNumber: "5289-74-7",
    MolecularFormula: "C27H44O7",
    MolecularWeight: 480.63,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1[C@]2([H])C[C@@H](O)[C@@H](O)C[C@]2(C)[C@]3([H])C([C@@](CC[C@]4([H])[C@]([C@H](O)CCC(C)(O)C)(O)C)(O)[C@]4(C)CC3)=C1",
    InChICode:
      "1S/C27H44O7/c1-23(2,32)9-8-22(31)26(5,33)21-7-11-27(34)16-12-18(28)17-13-19(29)20(30)14-24(17,3)15(16)6-10-25(21,27)4/h12,15,17,19-22,29-34H,6-11,13-14H2,1-5H3/t15-,17-,19+,20-,21-,22+,24+,25+,26+,27+/m0/s1",
    InChIKey: "NKDFYOWSKOHCCO-YPVLXUMRSA-N",
    PubChemCID: "5459840",
    MDLNumber: "MFCD00036740",
    InventoryStatus: "Under Processing",
    Analogues: "Available",
    type: "underProcessing",
  },
  {
    id: 69,
    Image: "/products/Asiaticacid-464-92-6.jpg",
    Product: "Asiatic acid",
    BotanicalSource: "Centella asiatica",
    IUPACName: "(4α)-2α,3β,23-trihydroxy-urs-12-en-28-oic acid",
    Synonyms: "Dammarolic Acid",
    CASNumber: "464-92-6",
    MolecularFormula: "C30H48O5",
    MolecularWeight: 488.7,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "OC[C@]1(C)[C@@H](O)[C@H](O)C[C@@]2(C)[C@@]1([H])CC[C@]3(C)[C@]2([H])CC=C4[C@@]3(C)CC[C@]5(C(O)=O)[C@@]4([H])[C@@H](C)[C@H](C)CC5",
    InChICode:
      "1S/C30H48O5/c1-17-9-12-30(25(34)35)14-13-28(5)19(23(30)18(17)2)7-8-22-26(3)15-20(32)24(33)27(4,16-31)21(26)10-11-29(22,28)6/h7,17-18,20-24,31-33H,8-16H2,1-6H3,(H,34,35)/t17-,18+,20-,21-,22-,23+,24+,26+,27+,28-,29-,30?/m1/s1",
    InChIKey: "JXSVIVRDWWRQRT-DPJHZOGISA-N",
    PubChemCID: "119034",
    MDLNumber: "MFCD00238541",
    InventoryStatus: "Under Processing",
    Analogues: "Available",
    type: "underProcessing",
  },
  {
    id: 70,
    Image: null,
    Product: "Asiaticoside",
    BotanicalSource: "Centella asiatica",
    IUPACName:
      "2α,3β,23-trihydroxy-urs-12-en-28-oic acid, O-6-deoxy-α-L-mannopyranosyl-(1→4α)-O-β-D-glucopyranosyl-(1→6)-β-D-glucopyranosyl ester",
    Synonyms: "Madecassol",
    CASNumber: "16830-15-2",
    MolecularFormula: "C48H78O19",
    MolecularWeight: 959.12,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "OC[C@]1(C)[C@@H](O)[C@H](O)C[C@@]2(C)[C@@]1([H])CC[C@]3(C)[C@]2([H])CC=C4[C@@]3(C)CC[C@]5(C(O[C@@H]6O[C@H](CO[C@H]7[C@H](O)[C@@H](O)[C@H](O[C@@]8([H])[C@H](O)[C@H](O)[C@@H](O)[C@H](C)O8)[C@@H](CO)O7)[C@@H](O)[C@H](O)[C@H]6O)=O)[C@@]4([H])[C@@H](C)[C@H](C)CC5",
    InChICode:
      "1S/C48H78O19/c1-20-10-13-48(15-14-46(6)23(29(48)21(20)2)8-9-28-44(4)16-24(51)39(60)45(5,19-50)27(44)11-12-47(28,46)7)43(61)67-42-36(58)33(55)31(53)26(65-42)18-62-40-37(59)34(56)38(25(17-49)64-40)66-41-35(57)32(54)30(52)22(3)63-41/h8,20-22,24-42,49-60H,9-19H2,1-7H3/t20-,21+,22+,24-,25-,26-,27-,28-,29+,30+,31-,32-,33+,34-,35-,36-,37-,38-,39+,40-,41+,42+,44+,45+,46-,47-,48+/m1/s1",
    InChIKey: "WYQVAPGDARQUBT-FGWHUCSPSA-N",
    PubChemCID: "11954171",
    MDLNumber: "MFCD06642601",
    InventoryStatus: "Under Processing",
    Analogues: "Available",
    type: "underProcessing",
  },
  {
    id: 71,
    Image: "/products/Bergenin-477-90-7.jpg",
    Product: "Bergenin",
    BotanicalSource: "Bergenia ligulata",
    IUPACName:
      "(2R,3S,4S,4aR,10bS)-3,4,4a,10b-tetrahydro-3,4,8,10-tetrahydroxy-2-(hydroxymethyl)-9-methoxy-pyrano[3,2-c][2]benzopyran-6(2H)-one",
    Synonyms: "Cuscutin",
    CASNumber: "477-90-7",
    MolecularFormula: "C14H16O9",
    MolecularWeight: 328.27,
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "OC1=C2C(C(O[C@@]3([H])[C@@]2([H])O[C@H](CO)[C@@H](O)[C@@H]3O)=O)=CC(O)=C1OC",
    InChICode:
      "1S/C14H16O9/c1-21-11-5(16)2-4-7(9(11)18)12-13(23-14(4)20)10(19)8(17)6(3-15)22-12/h2,6,8,10,12-13,15-19H,3H2,1H3/t6-,8-,10+,12+,13-/m1/s1",
    InChIKey: "YWJXCIXBAKGUKZ-HJJNZUOJSA-N",
    PubCID: "66065",
    MDLNumber: "MFCD00133120",
    InventoryStatus: "Under Processing",
    Analogues: "Available",
    type: "underProcessing",
  },
  {
    id: 72,
    Image: "/products/Carnosicacid-3650-09-7.jpg",
    Product: "Carnosic acid",
    BotanicalSource: "Rosmarinus officinalis",
    IUPACName:
      "(4aR,10aS)-5,6-Dihydroxy-7-isopropyl-1,1-dimethyl-1,3,4,9,10,10a-hexahydro-2H-phenanthrene-4a-carboxylic acid",
    Synonyms: "Salvin",
    CASNumber: "3650-09-7",
    MolecularFormula: "C20H28O4",
    MolecularWeight: 332.43,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC(C)c1cc2CC[C@H]3C(C)(C)CCC[C@]3(C(O)=O)c2c(O)c1O",
    InChICode:
      "1S/C20H28O4/c1-11(2)13-10-12-6-7-14-19(3,4)8-5-9-20(14,18(23)24)15(12)17(22)16(13)21/h10-11,14,21-22H,5-9H2,1-4H3,(H,23,24)/t14-,20+/m0/s1",
    InChIKey: "QRYRORQUOLYVBU-VBKZILBWSA-N",
    PubChemCID: "65126",
    MDLNumber: "MFCD02259459",
    InventoryStatus: "Under Processing",
    Analogues: "Available",
    type: "underProcessing",
  },
  {
    id: 73,
    Image: "/products/Carnosol-5957-80-2.jpg",
    Product: "Carnosol",
    BotanicalSource: "Rosmarinus officinalis",
    IUPACName:
      "(1R,8S,10S)-3,4-dihydroxy-11,11-dimethyl-5-propan-2-yl-16-oxatetracyclo[6.6.2.01,10.02,7]hexadeca-2,4,6-trien-15-one",
    Synonyms: "Picrosalvin",
    CASNumber: "5957-80-2",
    MolecularFormula: "C20H26O4",
    MolecularWeight: 330.42,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC(C)c1cc2[C@@H]3C[C@H]4C(C)(C)CCC[C@]4(C(=O)O3)c2c(O)c1O",
    InChICode:
      "1S/C20H26O4/c1-10(2)11-8-12-13-9-14-19(3,4)6-5-7-20(14,18(23)24-13)15(12)17(22)16(11)21/h8,10,13-14,21-22H,5-7,9H2,1-4H3/t13-,14-,20+/m0/s1",
    InChIKey: "XUSYGBPHQBWGAD-PJSUUKDQSA-N",
    PubChemCID: "442009",
    MDLNumber: "MFCD02752467",
    InventoryStatus: "Under Processing",
    Analogues: "Available",
    type: "underProcessing",
  },
  {
    id: 74,
    Image: "/products/Chlorogenicacid-327-97-9.jpg",
    Product: "Chlorogenic acid",
    BotanicalSource: "Coffee robusta",
    IUPACName:
      "1,4,5-Trihydroxycyclohexanecarboxylic acid 3-(3,4-dihydroxycinnamate)",
    Synonyms: "CGA",
    CASNumber: "327-97-9",
    MolecularFormula: "C16H18O9",
    MolecularWeight: 354.31,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O[C@@H]1C[C@](O)(C[C@@H](OC(=O)\\C=C\\c2ccc(O)c(O)c2)[C@@H]1O)C(O)=O",
    InChICode:
      "1S/C16H18O9/c17-9-3-1-8(5-10(9)18)2-4-13(20)25-12-7-16(24,15(22)23)6-11(19)14(12)21/h1-5,11-12,14,17-19,21,24H,6-7H2,(H,22,23)/b4-2+/t11-,12-,14-,16+/m1/s1",
    InChIKey: "CWVRJTMFETXNAD-JUHZACGLSA-N",
    PubChemCID: "1794427",
    MDLNumber: "MFCD00003862",
    InventoryStatus: "Under Processing",
    Analogues: "Available",
    type: "underProcessing",
  },
  {
    id: 75,
    Image: "/products/Conessine-546-06-5.jpg",
    Product: "Conessine",
    BotanicalSource: "Holarrhena antidysenterica",
    IUPACName: "(3β)-N,N-dimethyl-con-5-enin-3-amine",
    Synonyms: "Wrightine",
    CASNumber: "546-06-5",
    MolecularFormula: "C24H40N2",
    MolecularWeight: 356.59,
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@H]1[C@@]2([H])CC[C@@]3([H])[C@]4([H])CC=C5C[C@@H](N(C)C)CC[C@]5(C)[C@@]4([H])CC[C@@]32CN1C",
    InChICode:
      "1S/C24H40N2/c1-16-20-8-9-22-19-7-6-17-14-18(25(3)4)10-12-23(17,2)21(19)11-13-24(20,22)15-26(16)5/h6,16,18-22H,7-15H2,1-5H3/t16-,18-,19+,20+,21-,22-,23-,24-/m0/s1",
    InChIKey: "GPLGAQQQNWMVMM-MYAJQUOBSA-N",
    PubChemCID: "441082",
    MDLNumber: "MFCD00016752",
    InventoryStatus: "Under Processing",
    Analogues: "Available",
    type: "underProcessing",
  },
  {
    id: 76,
    Image: "/products/Cubebin-18423-69-3.jpg",
    Product: "Cubebin",
    BotanicalSource: "Piper cubeb",
    IUPACName: "(2S,3R,4R)-3,4-bis(1,3-benzodioxol-5-ylmethyl)oxolan-2-ol",
    synonyms: "Beta Cubebin",
    CASNumber: "18423-69-3",
    MolecularFormula: "C20H20O6",
    MolecularWeight: "356.4",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C1[C@@H]([C@H]([C@H](O1)O)CC2=CC3=C(C=C2)OCO3)CC4=CC5=C(C=C4)OCO5",
    InChICode:
      "1S/C20H20O6/c21-20-15(6-13-2-4-17-19(8-13)26-11-24-17)14(9-22-20)5-12-1-3-16-18(7-12)25-10-23-16/h1-4,7-8,14-15,20-21H,5-6,9-11H2/t14-,15+,20-/m0/s1",
    InChIKey: "DIYWRNLYKJKHAM-MDOVXXIYSA-N",
    PubChemCID: "117443",
    MDLNumber: "MFCD00213880",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 77,
    Image: "/products/Decaetylnimbin-18609-16-0.jpg",
    Product: "Deacetylnimbin",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "2-(3-furanyl)-3,3a,4a,5,5a,6,9,9a,10,10a-decahydro-5-hydroxy-6-(methoxycarbonyl)-1,6,9a,10a-tetramethyl-9-oxo-, methyl ester, (2R,3aR,4aS,5R,5aR,6R,9aR,10S,10aR)-",
    Synonyms: "6-Deacetylnimbin",
    CASNumber: "18609-16-0",
    MolecularFormula: "C28H34O8",
    MolecularWeight: "498.56",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    specificRotation: null,
    SMILES:
      "CC1=C2[C@@H](C[C@H]1C3=COC=C3)O[C@H]4[C@@]2([C@@H]([C@@]5([C@@H]([C@H]4O)[C@](C=CC5=O)(C)C(=O)OC)C)CC(=O)OC)C",
    InChICode:
      "1S/C28H34O8/c1-14-16(15-8-10-35-13-15)11-17-21(14)28(4)18(12-20(30)33-5)27(3)19(29)7-9-26(2,25(32)34-6)23(27)22(31)24(28)36-17/h7-10,13,16-18,22-24,31H,11-12H2,1-6H3/t16-,17-,18-,22-,23+,24-,26-,27+,28-/m1/s1",
    InChIKey: "CTBHKOAPXBDFPX-PQYHCQQJSA-N",
    PubChemCID: "10505484",
    MDLNumber: "MFCD02259467",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 78,
    Image: "/products/Deacetylsalannin-1110-56-1.jpg",
    Product: "Deacetylsalannin",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "[(1R,2S,4R,6R,9R,10R,11R,12S,14R,15R,18R)-6-(furan-3-yl)-14-hydroxy-10-(2-methoxy-2-oxoethyl)-7,9,11,15-tetramethyl-3,17-dioxapentacyclo[9.6.1.02,9.04,8.015,18]octadec-7-en-12-yl] (E)-2-methylbut-2-enoate",
    Synonyms: "3-Deacetylsalannin",
    CASNumber: "1110-56-1",
    MolecularFormula: "C32H42O8",
    MolecularWeight: "554.67",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C/C=C(\\C)/C(=O)O[C@H]1C[C@H]([C@]2(CO[C@@H]3[C@@H]2[C@]1([C@H]([C@]4([C@@H]3O[C@H]5C4=C([C@@H](C5)C6=COC=C6)C)C)CC(=O)OC)C)C)O",
    InChICode:
      "1S/C32H42O8/c1-8-16(2)29(35)40-23-13-22(33)30(4)15-38-26-27(30)31(23,5)21(12-24(34)36-7)32(6)25-17(3)19(18-9-10-37-14-18)11-20(25)39-28(26)32/h8-10,14,19-23,26-28,33H,11-13,15H2,1-7H3/b16-8+/t19-,20-,21-,22-,23+,24-,26-,27+,28-,30-,31+,32-/m1/s124+,27-,28+,29+,30-/m0/s1",
    InChIKey: "MJNRBOGIPLCVIM-LJEOTECVSA-N",
    PubChemCID: "14458886",
    MDLNumber: "MFCD03427689",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 79,
    Image: "/products/Deacylgymnemicacid-121686-42-8.jpg",
    Product: "Deacylgymnemic acid",
    BotanicalSource: "Gymnema sylvestre",
    IUPACName:
      "(3β,4α,16β-21β,22α)-16,21,22,23,28-Pentahydroxyolean-12-en-3-yl β-D-glucopyranosiduronic acid",
    Synonyms: "3-O-β-D-Glucuronopyranosyl gymnemagenin",
    CASNumber: "121686-42-8",
    MolecularFormula: "C36H58O12",
    MolecularWeight: "682.84",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    specificRotation: null,
    SMILES:
      "CC1(C)C[C@H]2C3=CC[C@@H]4[C@@]5(C)CC[C@H](O[C@@H]6O[C@@H]([C@@H](O)[C@H](O)[C@H]6O)C(O)=O)[C@@](C)(CO)[C@@H]5CC[C@@]4(C)[C@]3(C)C[C@H](O)[C@@]2(CO)[C@@H](O)[C@@]1O",
    InChICode:
      "1S/C36H58O12/c1-31(2)13-18-17-7-8-20-32(3)11-10-22(47-30-25(42)23(40)24(41)26(48-30)29(45)46)33(4,15-37)19(32)9-12-34(20,5)35(17,6)14-21(39)36(18,16-38)28(44)27(31)43/h7,18-28,30,37-44H,8-16H2,1-6H3,(H,45,46)/t18-,19+,20+,21-,22-,23-,24-,25+,26-,27-,28-,30+,32-,33-,34+,35+,36-/m0/s1",
    InChIKey: "NXUZSZLFZAMZLC-SOLHJDTNSA-N",
    PubChemCID: "129465",
    MDLNumber: "MFCD07779149",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 80,
    Image: "/products/Garcinol-78824-30-3.jpg",
    Product: "Garcinol",
    BotanicalSource: "Garcinia indica",
    IUPACName:
      "(1R,5R,7R)-3-(3,4-dihydroxybenzoyl)-4-hydroxy-8,8-dimethyl-5-((S)-5-methyl-2-(prop-1-en-2-yl)hex-4-en-1-yl)-1,7-bis(3-methylbut-2-en-1-yl)bicyclo[3.3.1]non-3-ene-2,9-dione",
    Synonyms: "Garcinol",
    CASNumber: "78824-30-3",
    MolecularFormula: "C38H50O6",
    MolecularWeight: "602.8",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CCCCCCCCCCCC1=C(O)C(=O)C=C(O)C1=O",
    InChICode:
      "1S/C38H50O6/c1-22(2)11-13-27(25(7)8)20-37-21-28(15-12-23(3)4)36(9,10)38(35(37)44,18-17-24(5)6)34(43)31(33(37)42)32(41)26-14-16-29(39)30(40)19-26/h11-12,14,16-17,19,27-28,31,39-40H,7,13,15,18,20-21H2,1-6,8-10H3/t27-,28+,31?,37+,38-/m0/s1",
    InChIKey: "YESGFVCPMZHDNK-FIXRDIOOSA-N",
    PubChemCID: "5281560",
    MDLNumber: "MFCD03700761",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 81,
    Image: "/products/Gartanin-33390-42-0.jpg",
    Product: "Gartanin",
    BotanicalSource: "Garcinia mangostana",
    IUPACName:
      "1,3,5,8-tetrahydroxy-2,4-bis(3-methyl-2-buten-1-yl)-9H-xanthen-9-one",
    Synonyms: "Gartanin",
    CASNumber: "33390-42-0",
    MolecularFormula: "C23H24O6",
    MolecularWeight: "396.43",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C2=C(O)C(CC=C(C)C)=C(O)C(CC=C(C)C)=C2OC3=C1C(O)=CC=C3O",
    InChICode:
      "1S/C23H24O6/c1-11(2)5-7-13-19(26)14(8-6-12(3)4)22-18(20(13)27)21(28)17-15(24)9-10-16(25)23(17)29-22/h5-6,9-10,24-27H,7-8H2,1-4H3",
    InChIKey: "OJXQLGQIDIPMTE-UHFFFAOYSA-N",
    PubChemCID: "5281633",
    MDLNumber: "MFCD09264654",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 82,
    Image: "/products/Glabridin-59870-68-7.jpg",
    Product: "Glabridin",
    BotanicalSource: "Glycyrrhiza glabra",
    IUPACName:
      "(R)-4-(3,4-Dihydro-8,8-dimethyl-2H,8H-benzo[1,2-b:3,4-b′]dipyran-3-yl)-1,3-benzenediol",
    Synonyms: "Glabridin",
    CASNumber: "59870-68-7",
    MolecularFormula: "C20H20O4",
    MolecularWeight: "324.37",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC1(C)Oc2ccc3C[C@@H](COc3c2C=C1)c4ccc(O)cc4O",
    InChICode:
      "1S/C20H20O4/c1-20(2)8-7-16-18(24-20)6-3-12-9-13(11-23-19(12)16)15-5-4-14(21)10-17(15)22/h3-8,10,13,21-22H,9,11H2,1-2H3/t13-/m0/s1",
    InChIKey: "LBQIJVLKGVZRIW-ZDUSSCGKSA-N",
    PubChemCID: "124052",
    MDLNumber: "MFCD03427694",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 83,
    Image: "/products/Gossypin-652-78-8.jpg",
    Product: "Gossypin",
    BotanicalSource: "Hibiscus vitifolius",
    IUPACName:
      "2-(3,4-Dihydroxyphenyl)-8-(β-D-glucopyranosyloxy)-3,5,7-trihydroxy- 4H-1-benzopyran-4-one, 3,3′,4′,5,7,8-Hexahydroxyflavone 8-glucoside, Gossypetin 8-glucoside",
    Synonyms: "Gossypetin-8-glucoside",
    CASNumber: "652-78-8",
    MolecularFormula: "C21H20O13",
    MolecularWeight: "480.38",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "OC[C@H]1O[C@@H](Oc2c(O)cc(O)c3C(=O)C(O)=C(Oc23)c4ccc(O)c(O)c4)[C@H](O)[C@@H](O)[C@@H]1O",
    InChICode:
      "1S/C21H20O13/c22-5-11-13(27)15(29)17(31)21(32-11)34-19-10(26)4-9(25)12-14(28)16(30)18(33-20(12)19)6-1-2-7(23)8(24)3-6/h1-4,11,13,15,17,21-27,29-31H,5H2,t11-,13-,15+,17-,21+/m1/s1",
    InChIKey: "SJRXVLUZMMDCNG-KKPQBLLMSA-N",
    PubChemCID: "5281621",
    MDLNumber: "MFCD00017429",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 84,
    Image: "/products/Gossypol-303-45-7.jpg",
    Product: "Gossypol",
    BotanicalSource: "Gossypium herbaceum",
    IUPACName:
      "(±)-2,2′-bis(8-Formyl-1,6,7-trihydroxy-5-isopropyl-3-methylnaphthalene)",
    Synonyms: "Gossypol",
    CASNumber: "303-45-7",
    MolecularFormula: "C30H30O8",
    MolecularWeight: "518.55",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C([H])C1=C(C(O)=C(C2=C(C)C=C(C(C(C)C)=C(O)C(O)=C3C([H])=O)C3=C2O)C(C)=C4)C4=C(C(C)C)C(O)=C1O",
    InChICode:
      "1S/C30H30O8/c1-11(2)19-15-7-13(5)21(27(35)23(15)17(9-31)25(33)29(19)37)22-14(6)8-16-20(12(3)4)30(38)26(34)18(10-32)24(16)28(22)36/h7-12,33-38H,1-6H3",
    InChIKey: "QBKSWRVVCFFDOT-UHFFFAOYSA-N",
    PubChemCID: "3503",
    MDLNumber: "MFCD00017352",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 85,
    Image: "/products/Gossypol-aceticacid-12542-36-8.jpg",
    Product: "Gossypol acetic acid",
    BotanicalSource: "Gossypium herbaceum",
    IUPACName:
      "5-[(1S,3aR,4R,6aR)-4-(1,3-Benzodioxol-5-yloxy)tetrahydro-1H,3H-furo[3,4-c]furan-1-yl]-1,3-benzodioxole",
    Synonyms: "Gossypol-acetic acid",
    CASNumber: "12542-36-8",
    MolecularFormula: "C30H30O8 · C2H4O2",
    MolecularWeight: "578.16",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[CC(O)=O.CC(C)c1c(O)c(O)c(C=O)c2c(O)c(c(C)cc12)-c3c(C)cc4c(C(C)C)c(O)c(O)c(C=O)c4c3O",
    InChICode:
      "1S/C30H30O8.C2H4O2/c1-11(2)19-15-7-13(5)21(27(35)23(15)17(9-31)25(33)29(19)37)22-14(6)8-16-20(12(3)4)30(38)26(34)18(10-32)24(16)28(22)36;1-2(3)4/h7-12,33-38H,1-6H3;1H2",
    InChIKey: "QTKKETQKZFTKAV-JVLKHFTDSA-N",
    PubChemCID: "5281632",
    MDLNumber: "MFCD00017349",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 86,
    Image: "/products/Gymnemagenin-22467-07-8.jpg",
    Product: "Gymnemagenin",
    BotanicalSource: "Gymnema sylvestre",
    IUPACName: "Olean-12-ene-3β,16β,21β,22α,23,28-hexol",
    Synonyms: "Gymnemagenin",
    CASNumber: "22467-07-8",
    MolecularFormula: "C30H50O6",
    MolecularWeight: "506.71",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC1(C)C[C@H]2C3=CC[C@@H]4[C@@]5(C)CC[C@H](O)[C@@](C)(CO)[C@@H]5CC[C@@]4(C)[C@]3(C)C[C@H](O)[C@@]2(CO)[C@@H](O)[C@@H]1O",
    InChICode:
      "1S/C30H50O6/c1-25(2)13-18-17-7-8-20-26(3)11-10-21(33)27(4,15-31)19(26)9-12-28(20,5)29(17,6)14-22(34)30(18,16-32)24(36)23(25)35/h7,18-24,31-36H,8-16H2,1-6H3/t18-,19+,20+,21-,22-,23-,24-,26-,27-,28+,29+,30-/m0/s1",
    InChIKey: "VKJLHZZPVLQJKG-ABHKXHSUSA-N",
    PubChemCID: "10051937",
    MDLNumber: "MFCD06656306",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 87,
    Image: "/products/Gymnestrogenin-19942-02-0.jpg",
    Product: "Gymnestrogenin",
    BotanicalSource: "Gymnema sylvestre",
    IUPACName:
      "(3S,4aS,5S,6aR,6aS,6bR,8aR,9R,10S,12aR,14bS)-4a,9-bis(hydroxymethyl)-2,2,6a,6b,9,12a-hexamethyl-1,3,4,5,6,6a,7,8,8a,10,11,12,13,14b-tetradecahydropicene-3,5,10-triol",
    Synonyms: "Gymnestrogenin",
    CASNumber: "19942-02-0",
    MolecularFormula: "C30H50O5",
    MolecularWeight: "490.7",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC1(CC2C3=CCC4C5(CCC(C(C5CCC4(C3(CC(C2(CC1O)CO)O)C)C)(C)CO)O)C)C",
    InChICode:
      "InChI=1S/C30H50O5/c1-25(2)13-19-18-7-8-21-26(3)11-10-22(33)27(4,16-31)20(26)9-12-28(21,5)29(18,6)14-24(35)30(19,17-32)15-23(25)34/h7,19-24,31-35H,8-17H2,1-6H3/t19-,20+,21+,22-,23-,24-,26-,27-,28+,29+,30+/m0/s1",
    InChIKey: "SIBYGGBNBRCVQI-DGNDGBPUSA-N",
    PubChemCID: "15560302",
    MDLNumber: "MFCD30724982",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 88,
    Image: "/products/Hibifolin-55366-56-8.jpg",
    Product: "Hibifolin",
    BotanicalSource: "Hibiscus vitifolius",
    IUPACName:
      "(2S,3S,4S,5R,6S)-6-[2-(3,4-dihydroxyphenyl)-3,5,7-trihydroxy-4-oxochromen-8-yl]oxy-3,4,5-trihydroxyoxane-2-carboxylic acid",
    Synonyms: "Gossypetin 8-glucuronide",
    CASNumber: "55366-56-8",
    MolecularFormula: "C21H18O14",
    MolecularWeight: "494.36",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C1=CC(=C(C=C1C2=C(C(=O)C3=C(O2)C(=C(C=C3O)O)OC4C(C(C(C(O4)C(=O)O)O)O)O)O)O)O",
    InChICode:
      "InChI=1S/C21H18O14/c22-6-2-1-5(3-7(6)23)16-13(28)11(26)10-8(24)4-9(25)17(18(10)33-16)34-21-15(30)12(27)14(29)19(35-21)20(31)32/h1-4,12,14-15,19,21-25,27-30H,(H,31,32)/t12-,14-,15+,19-,21+/m0/s1",
    InChIKey: "KHVMAMXQPVHXTJ-ORYXKJSJSA-N",
    PubChemCID: "5490334",
    MDLNumber: "MFCD28987619",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 89,
    Image: "/products/Hinokinin-26543-89-5.jpg",
    Product: "Hinokinin",
    BotanicalSource: "Piper cubeb",
    IUPACName: "(3R,4R)-3,4-bis(1,3-benzodioxol-5-ylmethyl)oxolan-2-one",
    Synonyms: "Cubebinolide",
    CASNumber: "26543-89-5",
    MolecularFormula: "C20H18O6",
    MolecularWeight: "354.35",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C1[C@@H]([C@H](C(=O)O1)CC2=CC3=C(C=C2)OCO3)CC4=CC5=C(C=C4)OCO5",
    InChICode:
      "1S/C20H18O6/c21-20-15(6-13-2-4-17-19(8-13)26-11-24-17)14(9-22-20)5-12-1-3-16-18(7-12)25-10-23-16/h1-4,7-8,14-15H,5-6,9-11H2/t14-,15+/m0/s1",
    InChIKey: "DDWGQGZPYDSYEL-LSDHHAIUSA-N",
    PubChemCID: "442879",
    MDLNumber: "MFCD29918433",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 90,
    Image: "/products/Isogarcinol-71117-97-0.jpg",
    Product: "Isogarcinol",
    BotanicalSource: "Garcinia indica",
    IUPACName:
      "(1S,3S,9R,11R)-7-(3,4-dihydroxybenzoyl)-4,4,10,10-tetramethyl-3,9,11-tris(3-methylbut-2-enyl)-5-oxatricyclo[7.3.1.01,6]tridec-6-ene-8,13-dione",
    Synonyms: "Cambogin",
    CASNumber: "71117-97-0",
    MolecularFormula: "C38H50O6",
    MolecularWeight: "602.8",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=CC[C@@H]1C[C@]23C[C@@H](C(OC2=C(C(=O)[C@@](C3=O)(C1(C)C)CC=C(C)C)C(=O)C4=CC(=C(C=C4)O)O)(C)C)CC=C(C)C)C",
    InChICode:
      "1S/C38H50O6/c1-22(2)11-14-26-20-37-21-27(15-12-23(3)4)36(9,10)44-33(37)30(31(41)25-13-16-28(39)29(40)19-25)32(42)38(34(37)43,35(26,7)8)18-17-24(5)6/h11-13,16-17,19,26-27,39-40H,14-15,18,20-21H2,1-10H3/t26-,27+,37+,38+/m1/s1",
    InChIKey: "KXTNVBQRLRYVCO-LPSZMIQCSA-N",
    PubChemCID: "11135781",
    MDLNumber: "MFCD31693292",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 91,
    Image: "/products/Karanjin-521-88-0.jpg",
    Product: "Karanjin",
    BotanicalSource: "Pongamia pinnata",
    IUPACName: "3-Methoxy-2-phenyl-4H-furo[2,3-h]-1-benzopyran-4-one",
    Synonyms: "Karanjin",
    CASNumber: "521-88-0",
    MolecularFormula: "C18H12O4",
    MolecularWeight: "292.29",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COC1=C(OC2=C(C1=O)C=CC3=C2C=CO3)C4=CC=CC=C4",
    InChICode:
      "1S/C18H12O4/c1-20-18-15(19)13-7-8-14-12(9-10-21-14)17(13)22-16(18)11-5-3-2-4-6-11/h2-10H,1H3",
    InChIKey: "LKPQNZRGGNOPPU-UHFFFAOYSA-N",
    PubChemCID: "100633",
    MDLNumber: "MFCD00017406",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 92,
    Image: "/products/Licochalcone-A-58749-22-7.jpg",
    Product: "Licochalcone A",
    BotanicalSource: "Glycyrrhiza glabra",
    IUPACName:
      "(E)-3-[5-(1,1-Dimethyl-2-propenyl)-4-hydroxy-2-methoxyphenyl]-1-(4-hydroxyphenyl)-2-propen-1-one",
    Synonyms: "Licochalcone A",
    CASNumber: "58749-22-7",
    MolecularFormula: "C21H22O4",
    MolecularWeight: "338.40",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COc1cc(O)c(cc1\\C=C\\C(=O)c2ccc(O)cc2)C(C)(C)C=C",
    InChICode:
      "1S/C21H22O4/c1-5-21(2,3)17-12-15(20(25-4)13-19(17)24)8-11-18(23)14-6-9-16(22)10-7-14/h5-13,22,24H,1H2,2-4H3/b11-8+",
    InChIKey: "KAZSKMJFUPEHHW-DHZHZOJOSA-N",
    PubChemCID: "5318998",
    MDLNumber: "MFCD01417903",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 93,
    Image: "/products/Madecassicacid-18449-41-7.jpg",
    Product: "Madecassic acid",
    BotanicalSource: "Centella asiatica",
    IUPACName: "(2α,3β,4α,6β)-2,3,6,23-tetrahydroxy-urs-12-en-28-oic acid",
    Synonyms: "Brahmic acid, 6βHydroxyasiatic acid",
    CASNumber: "18449-41-7",
    MolecularFormula: "C30H48O6",
    MolecularWeight: "504.70",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "OC[C@]1(C)[C@@H](O)[C@H](O)C[C@@]2(C)[C@@]1([H])[C@H](O)C[C@]3(C)[C@]2([H])CC=C4[C@@]3(C)CC[C@]5(C(O)=O)[C@@]4([H])[C@@H](C)[C@H](C)CC5",
    InChICode:
      "1S/C30H48O6/c1-16-9-10-30(25(35)36)12-11-28(5)18(22(30)17(16)2)7-8-21-26(3)13-20(33)24(34)27(4,15-31)23(26)19(32)14-29(21,28)6/h7,16-17,19-24,31-34H,8-15H2,1-6H3,(H,35,36)/t16-,17+,19-,20-,21-,22+,23-,24+,26-,27+,28-,29-,30?/m1/s1",
    InChIKey: "PRAUVHZJPXOEIF-QUKJEOBDSA-N",
    PubChemCID: "73412",
    MDLNumber: "MFCD11559128",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 94,
    Image: "/products/Madecassoside-34540-22-2.jpg",
    Product: "Madecassoside",
    BotanicalSource: "Centella asiatica",
    IUPACName:
      "(2α,3β,4α,6β)-2,3,6,23-tetrahydroxy-urs-12-en-28-oic acid-O-6-deoxy-α-L-mannopyranosyl-(1→4)-O-β-D-glucopyranosyl-(1→6)-β-D-glucopyranosyl ester",
    Synonyms: "Asiaticoside A",
    CASNumber: "34540-22-2",
    MolecularFormula: "C48H78O20",
    MolecularWeight: "975.12",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C(C1(CCC(C)C2C)C2C3=CCC4C(CC(O)C5C4(CC(O)C(O)C5(C)CO)C)(C)C3(C)CC1)OC(C(C(O)C6O)O)OC6COC(C(C(O)C7OC(OC(C)C(O)C8O)C8O)O)OC7CO",
    InChICode:
      "1S/C48H78O20/c1-19-10-11-48(13-12-46(6)22(28(48)20(19)2)8-9-27-44(4)14-24(52)39(61)45(5,18-50)38(44)23(51)15-47(27,46)7)43(62)68-42-35(59)32(56)30(54)26(66-42)17-63-40-36(60)33(57)37(25(16-49)65-40)67-41-34(58)31(55)29(53)21(3)64-41/h8,19-21,23",
    InChIKey: "BNMGUJRJUUDLHW-UHFFFAOYSA-N",
    PubChemCID: "45356919",
    MDLNumber: null,
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 95,
    Image: "/products/Mangostinalpha-6147-11-1.jpg",
    Product: "Mangostin alpha",
    BotanicalSource: "Garcinia mangostana",
    IUPACName:
      "1,3,6-trihydroxy-7-methoxy-2,8-bis(3-methylbut-2-enyl)xanthen-9-one",
    Synonyms: "Mangostin",
    CASNumber: "6147-11-1",
    MolecularFormula: "C24H26O6",
    MolecularWeight: "410.46",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COc1c(O)cc2Oc3cc(O)c(C\\C=C(\\C)C)c(O)c3C(=O)c2c1C\\C=C(/C)C",
    InChICode:
      "1S/C24H26O6/c1-12(2)6-8-14-16(25)10-19-21(22(14)27)23(28)20-15(9-7-13(3)4)24(29-5)17(26)11-18(20)30-19/h6-7,10-11,25-27H,8-9H2,1-5H3",
    InChIKey: "GNRIZKKCNOBBMO-UHFFFAOYSA-N",
    PubChemCID: "5281650",
    MDLNumber: "MFCD00135200",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 96,
    Image: "/products/Mangostinbeta-20931-37-7.jpg",
    Product: "Mangostin beta",
    BotanicalSource: "Garcinia mangostana",
    IUPACName:
      "1,6-dihydroxy-3,7-dimethoxy-2,8-bis(3-methyl-2-buten-1-yl)-9H-xanthen-9-one",
    Synonyms: "Mangostin beta",
    CASNumber: "20931-37-7",
    MolecularFormula: "C25H28O6",
    MolecularWeight: "424.49",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=C2C(OC(C=C(O)C(OC)=C3C/C=C(C)/C)=C3C2=O)=CC(OC)=C1C/C=C(C)/C",
    InChICode:
      "1S/C25H28O6/c1-13(2)7-9-15-18(29-5)12-20-22(23(15)27)24(28)21-16(10-8-14(3)4)25(30-6)17(26)11-19(21)31-20/h7-8,11-12,26-27H,9-10H2,1-6H3",
    InChIKey: "YRKKJHJIWCRNCW-UHFFFAOYSA-N",
    PubChemCID: "5495925",
    MDLNumber: "MFCD00951751",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 97,
    Image: "/products/Mangostingama-31271-07-5.jpg",
    Product: "Mangostin gama",
    BotanicalSource: "Garcinia mangostana",
    IUPACName: "1,3,6,7-Tetrahydroxy-2,8-bis(3,3-dimethylallyl)xanthone",
    Synonyms: "Normangostin",
    CASNumber: "31271-07-5",
    MolecularFormula: "C23H24O6",
    MolecularWeight: "396.43",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=CC(OC2=CC(O)=C(CC=C(C)C)C(O)=C2C3=O)=C3C(CC=C(C)C)=C1O",
    InChICode:
      "1S/C23H24O6/c1-11(2)5-7-13-15(24)9-18-20(22(13)27)23(28)19-14(8-6-12(3)4)21(26)16(25)10-17(19)29-18/h5-6,9-10,24-27H,7-8H2,1-4H3",
    InChIKey: "VEZXFTKZUMARDU-UHFFFAOYSA-N",
    PubChemCID: "5464078",
    MDLNumber: "MFCD00951752",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 98,
    Image: "/products/MuristeroneA-38778-30-2.jpg",
    Product: "Muristerone A",
    BotanicalSource: "Ipomoea hederacea",
    IUPACName: "2β,3β,5β,11α,14,20,22R-heptahydroxy-cholest-7-en-6-one",
    Synonyms: "Mur A",
    CASNumber: "38778-30-2",
    MolecularFormula: "C27H44O8",
    MolecularWeight: "496.64",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(C)CC[C@@H](O)[C@](C)(O)[C@@]1([H])CC[C@@]2(O)C3=CC([C@]4(O)C[C@@H](O)[C@@H](O)C[C@]4(C)[C@@]3([H])[C@H](O)C[C@@]21C)=O",
    InChICode:
      "1S/C27H44O8/c1-14(2)6-7-20(31)25(5,33)19-8-9-26(34)15-10-21(32)27(35)13-17(29)16(28)11-24(27,4)22(15)18(30)12-23(19,26)3/h10,14,16-20,22,28-31,33-35H,6-9,11-13H2,1-5H3/t16-,17+,18+,19-,20+,22+,23+,24+,25+,26+,27+/m0/s1",
    InChIKey: "LRJUYAVTHIEHAI-LHBNDURVSA-N",
    PubChemCID: "122217",
    MDLNumber: "MFCD00056450",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 99,
    Image: "/products/Nimbin-5945-86-8.jpg",
    Product: "Nimbin",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "(2R,3aR,4aS,5R,5aR,6R,9aR,10S,10aR)-5-(acetyloxy)-2-(3-furanyl)-3,3a,4a,5,5a,6,9,9a,10,10a-decahydro-6-(methoxycarbonyl)-1,6,9a,10a-tetramethyl-9-oxo-2H-cyclopenta[b]naphtho[2,3-d]furan-10-acetic acid, methyl ester",
    Synonyms: "Nimbin",
    CASNumber: "5945-86-8",
    MolecularFormula: "C30H36O9",
    MolecularWeight: "540.6",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC1=C2[C@@]3(C)[C@H](CC(OC)=O)[C@@]4(C)C(C=C[C@](C(OC)=O)(C)[C@]4([H])[C@@H](OC(C)=O)[C@@]3([H])O[C@]2([H])C[C@@]1([H])C5=COC=C5)=O",
    InChICode:
      "1S/C30H36O9/c1-15-18(17-9-11-37-14-17)12-19-23(15)30(5)20(13-22(33)35-6)29(4)21(32)8-10-28(3,27(34)36-7)25(29)24(26(30)39-19)38-16(2)31/h8-11,14,18-20,24-26H,12-13H2,1-7H3/t18-,19-,20-,24-,25+,26-,28-,29+,30-/m1/s1",
    InChIKey: "NHOIBRJOQAYBJT-IMGVWCFESA-N",
    PubChemCID: "108058",
    MDLNumber: "MFCD23103739",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 100,
    Image: "/products/Oleanolic-acid-508-02-1.jpg",
    Product: "Oleanolic acid",
    BotanicalSource: "Oscimum sanctum",
    IUPACName: "(+)-Oleanolic acid, 3beta-Hydroxyolean-12-en-28-oic acid",
    Synonyms: "Caryophyllin",
    CASNumber: "508-02-1",
    MolecularFormula: "C30H48O3",
    MolecularWeight: "456.70",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[H][C@@]12CC(C)(C)CC[C@@]1(CC[C@]3(C)C2=CC[C@]4([H])[C@@]5(C)CC[C@H](O)C(C)(C)[C@]5([H])CC[C@@]34C)C(O)=O",
    InChICode:
      "1S/C30H48O3/c1-25(2)14-16-30(24(32)33)17-15-28(6)19(20(30)18-25)8-9-22-27(5)12-11-23(31)26(3,4)21(27)10-13-29(22,28)7/h8,20-23,31H,9-18H2,1-7H3,(H,32,33)/t20-,21-,22+,23-,27-,28+,29+,30-/m0/s1",
    InChIKey: "MIJYXULNPSFWEK-GTOFXWBISA-N",
    PubChemCID: "10494",
    MDLNumber: "MFCD00064914",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 101,
    Image: "/products/Pellitorine-18836-52-7.jpg",
    Product: "Pellitorine",
    BotanicalSource: "Piper longum",
    IUPACName: "(2E,4E)-N-(2-Methylpropyl)-2,4-decadienamide",
    Synonyms: "trans-Pellitorin",
    CASNumber: "18836-52-7",
    MolecularFormula: "C14H25NO",
    MolecularWeight: "223.35",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C(/C=C/C=C/CCCCC)NCC(C)C",
    InChICode:
      "1S/C14H25NO/c1-4-5-6-7-8-9-10-11-14(16)15-12-13(2)3/h8-11,13H,4-7,12H2,1-3H3,(H,15,16)/b9-8+,11-10+",
    InChIKey: "MAGQQZHFHJDIRE-BNFZFUHLSA-N",
    PubChemCID: "5318516",
    MDLNumber: "MFCD01735995",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 102,
    Image: "/products/Picrotin-21416-53-5.jpg",
    Product: "Picrotin",
    BotanicalSource: "Anamirta cocculus",
    IUPACName:
      "(1aR,2aR,3S,6R,6aS,8aS,8bR,9S)-hexahydro-2a-hydroxy-9-(1-hydroxy-1-methylethyl)-8b-methyl-3,6-methano-8H-1,5,7-trioxacyclopenta[ij]cycloprop[a]azulene-4,8(3H)-dione",
    Synonyms: "Picrotin",
    CASNumber: "21416-53-5",
    MolecularFormula: "C15H18O7",
    MolecularWeight: "310.30",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC(C)(O)C1C2OC(=O)C1C3(O)CC4OC45C(=O)OC2C35C",
    InChICode:
      "1S/C15H18O7/c1-12(2,18)6-7-10(16)20-8(6)9-13(3)14(7,19)4-5-15(13,22-5)11(17)21-9/h5-9,18-19H,4H2,1-3H3",
    InChIKey: "RYEFFICCPKWYML-UHFFFAOYSA-N",
    PubChemCID: "442291",
    MDLNumber: "MFCD00148946",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 103,
    Image: "/products/Picrotoxin-124-87-8.jpg",
    Product: "Picrotoxin",
    BotanicalSource: "Anamirta cocculus",
    IUPACName: null,
    Synonyms: "Cocculin",
    CASNumber: "124-87-8",
    MolecularFormula: "C30H34O13",
    MolecularWeight: "602.58",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[H][C@@]12C[C@@]3(O)[C@@]4([H])[C@H](C(C)=C)[C@]([H])(OC4=O)[C@@]5([H])OC(=O)[C@]1(O2)[C@@]35C.[H][C@@]67C[C@@]8(O)[C@@]9([H])[C@@H]([C@]([H])(OC9=O)[C@@]%10([H])OC(=O)[C@]6(O7)[C@@]8%10C)C(C)(C)O",
    InChICode:
      "1S/C15H18O7.C15H16O6/c1-12(2,18)6-7-10(16)20-8(6)9-13(3)14(7,19)4-5-15(13,22-5)11(17)21-9;1-5(2)7-8-11(16)19-9(7)10-13(3)14(8,18)4-6-15(13,21-6)12(17)20-10/h5-9,18-19H,4H2,1-3H3;6-10,18H,1,4H2,2-3H3/t5-,6+,7+,8+,9-,13-,14-,15+;6-,7+,8+,9+,10-,13-,14-,15+/m11/s1",
    InChIKey: "VJKUPQSHOVKBCO-NGKRNLQBSA-N",
    PubChemCID: "31304",
    MDLNumber: "MFCD00074824",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 104,
    Image: "/products/Picrotoxinin-17617-45-7.jpg",
    Product: "Picrotoxinin",
    BotanicalSource: "Anamirta cocculus",
    IUPACName:
      "(1R,3R,5S,8S,9R,12S,13R,14R)-1-hydroxy-13-methyl-14-prop-1-en-2-yl-4,7,10-trioxapentacyclo[6.4.1.19,12.03,5.05,13]tetradecane-6,11-dione",
    Synonyms: "Picrotoxinin",
    CASNumber: "17617-45-7",
    MolecularFormula: "C15H16O6",
    MolecularWeight: "292.28",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[H][C@@]12OC(=O)[C@]([H])([C@@H]1C(C)=C)[C@]3(O)C[C@H]4O[C@]45C(=O)O[C@@]2([H])[C@]35C",
    InChICode:
      "1S/C15H16O6/c1-5(2)7-8-11(16)19-9(7)10-13(3)14(8,18)4-6-15(13,21-6)12(17)20-10/h6-10,18H,1,4H2,2-3H3/t6-,7+,8+,9-,10-,13-,14-,15+/m1/s1",
    InChIKey: "PIMZUZSSNYHVCU-KBLUICEQSA-N",
    PubChemCID: "442292",
    MDLNumber: "MFCD00078765",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 105,
    Image: "/products/Piperlongumine-20069-09-4.jpg",
    Product: "Piperlongumine",
    BotanicalSource: "Piper longum",
    IUPACName:
      "5,6-dihydro-1-[(2E)-1-oxo-3-(3,4,5-trimethoxyphenyl)-2-propen-1-yl]-2(1H)-pyridinone",
    Synonyms: "Piplartine",
    CASNumber: "20069-09-4",
    MolecularFormula: "C17H19NO5",
    MolecularWeight: "317.34",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COC(C(OC)=C1)=C(OC)C=C1/C=C/C(N2C(C=CCC2)=O)=O",
    InChICode:
      "1S/C17H19NO5/c1-21-13-10-12(11-14(22-2)17(13)23-3)7-8-16(20)18-9-5-4-6-15(18)19/h4,6-8,10-11H,5,9H2,1-3H3/b8-7+",
    InChIKey: "VABYUUZNAVQNPG-BQYQJAHWSA-N",
    PubChemCID: "637858",
    MDLNumber: "MFCD00075706",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 106,
    Image: "/products/Plumbagin-481-42-5.jpg",
    Product: "Plumbagin",
    BotanicalSource: "Plumbago zeylanica",
    IUPACName: "5-Hydroxy-2-methyl-1,4-naphthoquinone",
    Synonyms: "Plumbagin",
    CASNumber: "481-42-5",
    MolecularFormula: "C12H8O3",
    MolecularWeight: "188.18",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC1=CC(C2=C(C1=O)C=CC=C2O)=O",
    InChICode: "1S/C11H8O3/c1-6-5-9(13)10-7(11(6)14)3-2-4-8(10)12/h2-5,12H,1H3",
    InChIKey: "VCMMXZQDRFWYSE-UHFFFAOYSA-N",
    PubChemCID: "10205",
    MDLNumber: "MFCD00001682",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 107,
    Image: "/products/Pongamol-484-33-3.jpg",
    Product: "Pongamol",
    BotanicalSource: "Pongamia pinnata",
    IUPACName: "1-(4-methoxy-1-benzofuran-5-yl)-3-phenylpropane-1,3-dione",
    Synonyms: "Lanceolatin C",
    CASNumber: "484-33-3",
    MolecularFormula: "C18H14O4",
    MolecularWeight: "294.30",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COC1=C(C=CC2=C1C=CO2)C(=O)CC(=O)C3=CC=CC=C3",
    InChICode:
      "1S/C18H14O4/c1-21-18-13(7-8-17-14(18)9-10-22-17)16(20)11-15(19)12-5-3-2-4-6-12/h2-10H,11H2,1H3",
    InChIKey: "XTLSKKJNOIMMBK-UHFFFAOYSA-N",
    PubChemCID: "689051",
    MDLNumber: "MFCD05618208",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 108,
    Image: "/products/Rosmarinicacid-20283-92-5.jpg",
    Product: "Rosmarinic acid",
    BotanicalSource: "Rosmarinus officinalis",
    IUPACName:
      "(R)-O-(3,4-Dihydroxycinnamoyl)-3-(3,4-dihydroxyphenyl)lactic acid",
    Synonyms: "Labiatenic acid",
    CASNumber: "20283-92-5",
    MolecularFormula: "C18H16O8",
    MolecularWeight: "360.31",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC(=O)[C@@H](Cc1ccc(O)c(O)c1)OC(=O)\\C=C\\c2ccc(O)c(O)c2",
    InChICode:
      "1S/C18H16O8/c19-12-4-1-10(7-14(12)21)3-6-17(23)26-16(18(24)25)9-11-2-5-13(20)15(22)8-11/h1-8,16,19-22H,9H2,(H,24,25)/b6-3+/t16-/m1/s1",
    InChIKey: "DOUMFZQKYFQNTF-WUTVXBCWSA-N",
    PubChemCID: "5281792",
    MDLNumber: "MFCD00017740",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 109,
    Image: "/products/Salannine-992-20-1.jpg",
    Product: "Salannin",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "[(1R,2S,4R,6R,9R,10R,11R,12S,14R,15R,18R)-14-acetyloxy-6-(furan-3-yl)-10-(2-methoxy-2-oxoethyl)-7,9,11,15-tetramethyl-3,17-dioxapentacyclo[9.6.1.02,9.04,8.015,18]octadec-7-en-12-yl] (E)-2-methylbut-2-enoate",
    Synonyms: "Margosan-O",
    CASNumber: "992-20-1",
    MolecularFormula: "C34H44O9",
    MolecularWeight: "596.7",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C/C=C(\\C)/C(=O)O[C@H]1C[C@H]([C@]2(CO[C@@H]3[C@@H]2[C@]1([C@H]([C@]4([C@@H]3O[C@H]5C4=C([C@@H](C5)C6=COC=C6)C)C)CC(=O)OC)C)C)OC(=O)C",
    InChICode:
      "1S/C34H44O9/c1-19-9-14-45(46-17-19)20(2)30-28(61-45)16-27-25-8-7-23-15-24(10-12-43(23,5)26(36)38-8)34(7)27-18(3)21(20-10-11-39-15-20)12-22(27)42-30(28)34/h9-11,15,21-25,28-30H,12-14,16H2,1-8H3/b17-9+/t21-,22-,23-,24-,25+,28-,29+,30-,32-,33+,34-/m1/s1",
    InChIKey: "CJHBVBNPNXOWBA-REXVOHEDSA-N",
    PubChemCID: "6437066",
    MDLNumber: "MFCD00210552",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 110,
    Image: "/products/Solamargine-20311-51-7.jpg",
    Product: "Solamargine",
    BotanicalSource: "Solanum nigrum",
    IUPACName:
      "(2S,3R,4R,5R,6S)-2-[(2R,3S,4S,5R,6R)-4-hydroxy-2-(hydroxymethyl)-6-[(1S,2S,4S,5'R,6R,7S,8R,9S,12S,13R,16S)-5',7,9,13-tetramethylspiro[5-oxapentacyclo[10.8.0.02,9.04,8.013,18]icos-18-ene-6,2'-piperidine]-16-yl]oxy-5-[(2S,3R,4R,5R,6S)-3,4,5-trihydroxy-6-methyloxan-2-yl]oxyoxan-3-yl]oxy-6-methyloxane-3,4,5-triol",
    Synonyms: "Solamargine",
    CASNumber: "20311-51-7",
    MolecularFormula: "C45H73NO15",
    MolecularWeight: "868.06",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC1CCC2(C(C3C(O2)CC4C3(CCC5C4CC=C6C5(CCC(C6)OC7C(C(C(C(O7)CO)OC8C(C(C(C(O8)C)O)O)O)O)OC9C(C(C(C(O9)C)O)O)O)C)C)C)NC1",
    InChICode:
      "1S/C45H73NO15/c1-19-9-14-45(46-17-19)20(2)30-28(61-45)16-27-25-8-7-23-15-24(10-12-43(23,5)26(36)38-8)34(7)27-18(3)21(20-10-11-39-15-20)12-22(27)42-30(28)34/h7,19-22,24-42,46-54H,8-18H2,1-6H3/t19-,20+,21+,22+,24+,25-,26+,27+,28+,29-,30+,31+,32+,33-,34-,35-,36-,37+,38-,39-,40+,41+,42-,43+,44+,45-/m1/s1",
    InChIKey: "MBWUSSKCCUMJHO-ZGXDEBHDSA-N",
    PubChemCID: "73611",
    MDLNumber: "MFCD30207844",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 111,
    Image: "/products/Solasodine-126-17-0.jpg",
    Product: "Solasodine",
    BotanicalSource: "Solanum nigrum",
    IUPACName: "(3β,22α,25R)-spirosol-5-en-3-ol",
    Synonyms: "Purapuridine",
    CASNumber: "126-17-0",
    MolecularFormula: "C27H43NO2",
    MolecularWeight: "413.64",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@@H]1[C@]2(NC[C@H](C)CC2)O[C@@]3([H])C[C@@]4([H])[C@]5([H])CC=C6C[C@@H](O)CC[C@]6(C)[C@@]5([H])CC[C@]4(C)[C@]31[H]",
    InChICode:
      "1S/C27H43NO2/c1-16-7-12-27(28-15-16)17(2)24-23(30-27)14-22-20-6-5-18-13-19(29)8-10-25(18,3)21(20)9-11-26(22,24)4/h5,16-17,19-24,28-29H,6-15H2,1-4H3/t16-,17+,19+,20-,21+,22+,23+,24+,25+,26+,27-/m1/s1",
    InChIKey: "KWVISVAMQJWJSZ-VKROHFNGSA-N",
    PubChemCID: "5250",
    MDLNumber: "MFCD00037844",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 112,
    Image: "/products/Solasonine-19121-58-5.jpg",
    Product: "Solasonine",
    BotanicalSource: "Solanum nigrum",
    IUPACName:
      "(2S,3R,4R,5R,6S)-2-[(2R,3R,4S,5S,6R)-5-hydroxy-6-(hydroxymethyl)-2-[(1S,2S,4S,5'R,6R,7S,8R,9S,12S,13R,16S)-5',7,9,13-tetramethylspiro[5-oxapentacyclo[10.8.0.02,9.04,8.013,18]icos-18-ene-6,2'-piperidine]-16-yl]oxy-4-[(2S,3R,4S,5S,6R)-3,4,5-trihydroxy-6-(hydroxymethyl)oxan-2-yl]oxyoxan-3-yl]oxy-6-methyloxane-3,4,5-triol",
    Synonyms: "Solasonine",
    CASNumber: "19121-58-5",
    MolecularFormula: "C45H73NO6",
    MolecularWeight: "884.06",
    Appearance: "Solid Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC1CCC2(C(C3C(O2)CC4C3(CCC5C4CC=C6C5(CCC(C6)OC7C(C(C(C(O7)CO)O)OC8C(C(C(C(O8)CO)O)O)O)OC9C(C(C(C(O9)C)O)O)O)C)C)C)NC1",
    InChICode:
      "1S/C45H73NO16/c1-19-8-13-45(46-16-19)20(2)30-27(62-45)15-26-24-7-6-22-14-23(9-11-43(22,4)25(24)10-12-44(26,30)5)57-42-39(61-40-36(54)34(52)31(49)21(3)56-40)38(33(51)29(18-48)59-42)60-41-37(55)35(53)32(50)28(17-47)58-41/h6,19-21,23-42,46-55H,7-18H2,1-5H3/t19-,20-,21-,23-,24+,25-,26-,27-,28+,29+,30-,31-,32+,33-,34+,35-,36+,37+,38-,39+,40-,41-,42+,43-,44-,45+/m0/s1",
    InChIKey: "QCTMYNGDIBTNSK-LFRCBARCSA-N",
    PubChemCID: "119247",
    MDLNumber: "MFCD00017611",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
  {
    id: 113,
    Image: "/products/Ursolicacid-77-52-1.jpg",
    Product: "Ursolic acid",
    BotanicalSource: "Oscimum sanctum",
    IUPACName: "3β-Hydroxy-12-ursen-28-ic acid",
    Synonyms: "Bungeolic Acid",
    CASNumber: "77-52-1",
    MolecularFormula: "C30H48O3",
    MolecularWeight: "456.7",
    Appearance: "Solid powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[H][C@@]12CC[C@]3(C)[C@]([H])(CC=C4[C@]5([H])[C@@H](C)[C@H](C)CC[C@@]5(CC[C@@]34C)C(O)=O)[C@@]1(C)CC[C@H](O)C2(C)C",
    InChICode:
      "1S/C30H48O3/c1-18-10-15-30(25(32)33)17-16-28(6)20(24(30)19(18)2)8-9-22-27(5)13-12-23(31)26(3,4)21(27)11-14-29(22,28)7/h8,18-19,21-24,31H,9-17H2,1-7H3,(H,32,33)/t18-,19+,21+,22-,23+,24+,27+,28-,29-,30+/m1/s1",
    InChIKey: "WCGUUGGRBIKTOS-GPOJBZKASA-N",
    PubChemCID: "64945",
    MDLNumber: "MFCD00009621",
    InventoryStatus: "Under Processing",
    type: "underProcessing",
  },
];

export default phytochemicals;
